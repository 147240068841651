import { createContext, useContext } from "react";
import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from "posthog-js/react";
import { debugValue } from "@/env/utils";
import { IS_DEVELOPMENT } from "@/lib/constants";
import {
	FEATURE_FLAG,
	FEATURE_FLAG_DEFAULT_VALUE,
	type FeatureFlagValue,
} from "@/lib/posthog/feature-flags";

export const FeatureFlagContext = createContext<FeatureFlagValue>(FEATURE_FLAG_DEFAULT_VALUE);

export function useFeatureFlags() {
	const context = useContext(FeatureFlagContext);

	if (!context) {
		throw new Error("useFeatureFlags must be used within a FeatureFlagContext");
	}

	return context;
}

export function FeatureFlagProvider(
	props: Omit<React.ComponentProps<typeof FeatureFlagContext.Provider>, "value">,
) {
	const isDevelopment = IS_DEVELOPMENT || debugValue("internal-devtools");

	const development = useFeatureFlagEnabled(FEATURE_FLAG.DEVELOPMENT);
	const helpdeskPhoneNumber = useFeatureFlagVariantKey(FEATURE_FLAG.HELPDESK_PHONE_NUMBER);
	const corevalueRefineEnabled = useFeatureFlagEnabled(FEATURE_FLAG.COREVALUE_REFINE_ENABLED);

	return (
		<FeatureFlagContext.Provider
			{...props}
			value={{
				[FEATURE_FLAG.DEVELOPMENT]: isDevelopment || development,
				[FEATURE_FLAG.HELPDESK_PHONE_NUMBER]: helpdeskPhoneNumber,
				[FEATURE_FLAG.COREVALUE_REFINE_ENABLED]: isDevelopment || corevalueRefineEnabled,
			}}
		/>
	);
}
