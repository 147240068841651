import { IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { merge } from "lodash-es";
import { motion } from "motion/react";
import { useShallow } from "zustand/shallow";
import { NumberTicker } from "@/components/magicui/number-ticker";
import { checkCircleIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { userGroupIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { authSelectors, useAuthStore } from "@/lib/store/auth";
import { cx } from "@/lib/style/cva.config";

export function CoreValueRankingRow({
	data,
	...props
}: React.ComponentProps<typeof motion.li> & {
	data?: {
		type?: "reflection" | "friend-check" | "saved" | "compiled" | undefined;
		name?: string | null | undefined;
		number?: number | null | undefined;
		score?: number | null | undefined;
		reason?: string | null | undefined;
		customName?: string | null | undefined;
	};
}) {
	const { i18n } = useTranslation();
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));
	const queryCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: isAuthenticated });
	const coreValue = queryCoreValues.data?.find((coreValue) => coreValue.name === data?.name);

	return (
		<motion.li
			{...props}
			className={cx(
				"flex cursor-pointer items-center justify-between gap-2 rounded-xl bg-brown-100 p-3",
				props.className,
			)}
			initial={merge(
				{
					opacity: 0,
					scaleX: 0.75,
					y: "1rem",
					display: "none",
				} satisfies (typeof props)["initial"],
				props.initial,
			)}
			animate={merge(
				{ opacity: 1, scaleX: 1, y: 0, display: "flex" } satisfies (typeof props)["animate"],
				props.animate,
			)}
		>
			<span className="flex flex-row gap-2 font-semibold text-brown-700">
				<span className="block min-w-6 text-end">{!!data?.number && `${data.number}.`}</span>
				<span className="block min-w-5 text-center">{coreValue?.emoji || "❓"}</span>
				<span>{data?.customName || data?.name || ""}</span>
			</span>

			{data?.type === "friend-check" ? (
				<IonIcon icon={userGroupIcon} className="size-5 text-brown-400" />
			) : data?.type === "compiled" ? (
				<>
					{data?.customName ? (
						<IonIcon icon={checkCircleIcon} className="size-5 text-brown-400" />
					) : (
						<div className="size-4 rounded-full border-2 border-brown-400" />
					)}
				</>
			) : (
				<NumberTicker
					className="text-end font-semibold text-brown-400"
					value={data?.score ?? 0}
					getTextContent={(value) =>
						Intl.NumberFormat(i18n.language, { style: "unit", unit: "percent" }).format(
							value.toFixed(0),
						)
					}
					initial="hidden"
					animate={data?.score ? "visible" : "hidden"}
					variants={{
						visible: { opacity: 1, pointerEvents: "auto" },
						hidden: { opacity: 0, pointerEvents: "none" },
					}}
					transition={{ duration: 0.25, delay: props.transition?.delay }}
					duration={0.25}
					checkInView={false}
					delay={props.transition?.delay}
				/>
			)}
		</motion.li>
	);
}
