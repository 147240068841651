import { motion, useMotionValue, useTransform } from "motion/react";
import { ReflectionCard } from "@/components/Reflection/Card";
import { type ReflectionSummary } from "@/lib/backend/api";
import { cx } from "@/lib/style/cva.config";

const MotionCard = motion.create(ReflectionCard);

export function DashboardReflectionWidgetCard({
	reflectionSummary,
	index,
	currentIndex,
	maxIndex,
	handleNext,
	...props
}: React.ComponentProps<typeof MotionCard> & {
	reflectionSummary: ReflectionSummary;
	index: number;
	currentIndex: number;
	maxIndex: number;
	handleNext: VoidFunction;
}) {
	const x = useMotionValue(0);
	const rotate = useTransform(x, [-250, 250], [-1, 1], { clamp: false });

	const stackIndex = (index - currentIndex + maxIndex) % maxIndex;

	const state =
		index === currentIndex || maxIndex === 1
			? "current"
			: (maxIndex > 4 ? maxIndex - 1 === stackIndex : false)
				? "passed"
				: "stack";

	const clampedStackIndex = Math.min(3, stackIndex);

	return (
		<MotionCard
			{...props}
			className={cx(
				"absolute bottom-0 h-[--height] max-h-[--height] min-h-[--height] w-full max-w-full select-none gap-3 [--height:9.5rem]",
				props.className,
			)}
			onClick={(event) => {
				if (x.get() >= 8 || x.get() <= -8) {
					return;
				}

				props.onClick?.(event);
			}}
			drag="x"
			dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
			onDrag={(_, info) => x.set(info.offset.x)}
			onDragEnd={(_, info) => {
				if (info.offset.x <= -48 || info.offset.x >= 48) {
					handleNext();
				}

				setTimeout(() => x.set(0), 250);
			}}
			style={{ cursor: "grab", rotate }}
			whileTap={{ cursor: "grabbing" }}
			initial={state}
			animate={state}
			variants={{
				passed: {
					opacity: 0,
					scaleX: 1,
					...(x.get() === 0
						? {
								x: 0,
								y: "1rem",
							}
						: {
								x: `${x.get() > 0 ? "" : "-"}50%`,
								y: 0,
							}),
					rotate: 0,
					zIndex: 1,
					pointerEvents: "none",
				},
				current: {
					opacity: 1,
					scaleX: 1,
					x: 0,
					y: 0,
					rotate: 0,
					zIndex: 0,
					pointerEvents: "auto",
				},
				stack: {
					opacity: clampedStackIndex > 2 ? 0 : clampedStackIndex === 2 ? 0.75 : 1,
					scaleX: 1 - 0.05 * clampedStackIndex,
					x: 0,
					y: `-${clampedStackIndex}rem`,
					rotate: 0,
					zIndex: -1 * clampedStackIndex,
					pointerEvents: "none",
				},
			}}
		/>
	);
}
