import {
	IonCheckbox,
	IonHeader,
	IonIcon,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonSkeletonText,
	IonSpinner,
	IonText,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { debounce, isEqual } from "lodash-es";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { FormTextarea } from "@/components/Form/Textarea";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { paperAirplaneIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

type MixedPostReflectionAnswer = {
	content: string;
	options: string[];
};

export function ModalReflectionQuestionFirstAnswer() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const router = useIonRouter();
	const modal = useSelectorModalStore.use.reflectionQuestionFirstAnswer();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const textareaRef = useRef<HTMLIonTextareaElement>(null);
	const [myData, { mutation: mutationMyData }] = useMyData();

	const queryReflectionChat = useExtractedQuery({
		queryKey: ["reflection", modal.id!] as const,
		queryFn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!modal.id,
	});

	useEffect(() => {
		if (!modal.isOpen) {
			return;
		}

		if (!modal.id || (!queryReflectionChat.isLoading && !queryReflectionChat.data)) {
			modalRef.current?.dismiss();
		}
	}, [modal.isOpen, modal.id, queryReflectionChat.isLoading, queryReflectionChat.data]);

	const id = modal.id!;
	const reflectionChat = queryReflectionChat.data!;
	const chatId = reflectionChat?.id;
	const initialValues = myData.reflection.questions[id];

	const form = useForm<MixedPostReflectionAnswer>({
		defaultValues: initialValues ?? { content: "", options: [] },
		validators: {
			onSubmit:
				(reflectionChat?.reflection?.initialMessageChoices?.length ?? 0 > 0)
					? z.object({
							content: z.string(),
							options: z
								.array(z.string())
								.min(1, t("fields.options.errors.min", { count: 1 }))
								.max(
									reflectionChat?.reflection?.initialMessageMaxChoices ?? 1,
									t("fields.options.errors.max", {
										count: reflectionChat?.reflection?.initialMessageMaxChoices ?? 1,
									}),
								),
						})
					: z.object({
							content: z.string().min(3, t("fields.answer.errors.min", { count: 3 })),
							options: z.array(z.string()),
						}),
		},
		onSubmit: async ({ value }) => {
			if (
				reflectionChat.reflection?.initialMessageChoices?.length &&
				reflectionChat.reflection?.initialMessageChoices.length > 0
			) {
				await backendService.reflection.reflectionControllerAnswerInitialQuestion(chatId, {
					choices: value.options,
				});
			} else {
				await backendService.reflection.reflectionControllerAnswerInitialQuestion(
					chatId,
					{
						content: value.content,
					},
					{
						format: "text",
					},
				);
			}

			posthog.capture("Reflection Question Answered", { questionId: id });

			form.reset();
			await mutationMyData.mutateAsync(
				(draft) => void (draft.reflection.questions[id] = form.store.state.values),
			);
			posthog.capture("Modal Reflection Question FirstAnswer Finished");
			modalRef.current?.dismiss();
			router.push(Routes.ReflectionQuestion({ id }), "forward", "push");
		},
	});

	useEffect(
		() =>
			form.store.subscribe(
				debounce(() => {
					// FUTURE: This fires twice on change, no big deal, but not super nice
					if (
						!!id &&
						form.store.state.isDirty &&
						!isEqual(form.store.state.values, initialValues)
					) {
						mutationMyData.mutate(
							(draft) => void (draft.reflection.questions[id] = form.store.state.values),
						);
					}
				}, 250),
			),
		[form.store, id, JSON.stringify(initialValues)],
	);

	if (!id || !reflectionChat) {
		return <></>;
	}

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen && !!modal.id}
			onWillPresent={() => {
				present("reflectionQuestionFirstAnswer");
				posthog.capture("Modal Reflection Question FirstAnswer Present");
			}}
			onDidPresent={() => textareaRef.current?.setFocus()}
			onWillDismiss={() => {
				dismiss("reflectionQuestionFirstAnswer", { id: null });
				posthog.capture("Modal Reflection Question FirstAnswer Dismiss");
				mutationMyData.mutate(
					(draft) => void (draft.reflection.questions[id] = form.store.state.values),
				);
				form.reset();
				queryReflectionChat.refetch();
			}}
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<Button
						slot="primary"
						data-attr="modal.reflection.question.first-answer.close"
						className="min-h-0 ion-bg-a-brown-300 ion-bg-brown-300 ion-bg-f-brown-300 ion-text-a-brown-700 ion-text-brown-700 ion-text-f-brown-700 ion-text-h-brown-700 ion-rounded-full ion-p-2.5"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content inModal className="part-scroll:gap-0 part-scroll:p-4 part-scroll:pt-2">
				<form
					className="flex flex-1 flex-col gap-4 rounded-3xl border border-brown-200 bg-brown-100 p-4 contain-paint"
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
				>
					<div className="flex flex-1 flex-col gap-2 pt-2">
						{queryReflectionChat.isLoading ? (
							<div className="flex flex-col">
								<IonSkeletonText animated className="h-4 w-5/6" />
								<IonSkeletonText animated className="h-4 w-1/2" />
							</div>
						) : (
							<>
								<IonText className="text-pretty font-semibold text-brown-700">
									{reflectionChat.reflection?.initialMessageContent}
								</IonText>

								{reflectionChat.reflection?.initialMessageChoices?.length &&
								reflectionChat.reflection?.initialMessageChoices.length > 0 ? (
									<form.Field
										key="options"
										name="options"
										children={(field) =>
											reflectionChat.reflection?.initialMessageChoices &&
											reflectionChat.reflection?.initialMessageMaxChoices &&
											reflectionChat.reflection?.initialMessageMaxChoices > 1 ? (
												<div
													className={cx(
														"flex flex-col gap-2",
														field.state.meta.isTouched && "ion-touched",
														!!field.state.meta.errors &&
															field.state.meta.errors.length > 0 &&
															"ion-invalid",
													)}
												>
													{reflectionChat.reflection?.initialMessageChoices?.map(
														(option, index) => {
															const checked = field.state.value.includes(option);

															return (
																<IonCheckbox
																	key={index}
																	data-attr="modal.reflection.question.first-answer.checkbox"
																	value={option}
																	labelPlacement="end"
																	justify="start"
																	name={field.name}
																	checked={checked}
																	onIonChange={() => {
																		if (checked) {
																			field.removeValue(field.state.value.indexOf(option));
																		} else {
																			field.pushValue(option);
																		}
																	}}
																	onIonBlur={field.handleBlur}
																	disabled={
																		((reflectionChat.reflection?.initialMessageMaxChoices ?? 0) <=
																			field.state.value.length &&
																			!checked) ||
																		field.state.meta.isValidating ||
																		field.form.state.isSubmitting
																	}
																	className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700 part-[label]:overflow-auto part-[label]:whitespace-normal"
																>
																	{option}
																</IonCheckbox>
															);
														},
													)}
													{!!field.state.meta.errors && !!field.state.meta.errors.length && (
														<span className="text-sm font-semibold text-danger-500">
															{getErrorText({
																language: i18n.language,
																errors: field.state.meta.errors,
															})}
														</span>
													)}
												</div>
											) : (
												<IonRadioGroup
													data-attr="modal.reflection.question.first-answer.radio-group"
													name={field.name}
													value={field.state.value}
													onIonChange={(event) => field.setValue([String(event.detail.value)])}
													onBlur={field.handleBlur}
													className={cx(
														"flex flex-col gap-2",
														field.state.meta.isTouched && "ion-touched",
														!!field.state.meta.errors &&
															field.state.meta.errors.length > 0 &&
															"ion-invalid",
													)}
												>
													{reflectionChat.reflection?.initialMessageChoices?.map(
														(option, index) => (
															<IonRadio
																key={index}
																data-attr="modal.reflection.question.first-answer.radio"
																value={option}
																labelPlacement="end"
																justify="start"
																disabled={
																	field.state.meta.isValidating || field.form.state.isSubmitting
																}
																className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700 part-[label]:overflow-auto part-[label]:whitespace-normal"
															>
																{option}
															</IonRadio>
														),
													)}
													{!!field.state.meta.errors && !!field.state.meta.errors.length && (
														<span className="text-sm font-semibold text-danger-500">
															{getErrorText({
																language: i18n.language,
																errors: field.state.meta.errors,
															})}
														</span>
													)}
												</IonRadioGroup>
											)
										}
									/>
								) : (
									<>
										<form.Field
											key="content"
											name="content"
											children={(field) => (
												<FormTextarea
													ref={textareaRef}
													data-attr="modal.reflection.question.first-answer.textarea"
													field={field}
													placeholder={t("fields.answer.placeholder")}
													inputMode="text"
													autocapitalize="sentences"
													onKeyDown={async (event) => {
														if (event.metaKey && event.code === "Enter") {
															field.handleChange(
																String((event.target as unknown as { value: string })?.value ?? ""),
															);

															form.handleSubmit();
														}
													}}
													className="flex flex-1 flex-col text-brown-700 [&_textarea]:!-mx-4 [&_textarea]:!box-content [&_textarea]:!px-4"
												/>
											)}
										/>
									</>
								)}
							</>
						)}
					</div>

					<div className="flex justify-end">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<Button
									type="submit"
									data-attr="modal.reflection.question.first-answer.submit"
									disabled={!canSubmit}
									shape="round"
									color="primary"
									size="default"
									className="min-h-0 part-native:size-12 part-native:min-h-0 part-native:rounded-full part-native:p-1 part-native:text-sm"
								>
									{isSubmitting ? (
										<IonSpinner slot="icon-only" />
									) : (
										<IonIcon slot="icon-only" icon={paperAirplaneIcon} className="size-3/5" />
									)}
								</Button>
							)}
						/>
					</div>
				</form>
			</Content>
		</IonModal>
	);
}
