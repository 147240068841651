import { useDeferredValue, useMemo, useState } from "react";
import { type DefaultError, type UseMutationOptions, useMutation } from "@tanstack/react-query";
import { type HttpResponse, type RequestParams } from "@/lib/backend/api";
import { events } from "@/utils/event-stream";

export function useMutationStream<TVariables = void>({
	mutationFn,
	...options
}: Omit<UseMutationOptions<string, DefaultError, TVariables>, "mutationFn"> & {
	mutationFn: (params: RequestParams, variables: TVariables) => Promise<HttpResponse<any, any>>;
}) {
	const [result, setResult] = useState<string | null>(null);
	const deferredResult = useDeferredValue(result);

	const mutation = useMutation<string, DefaultError, TVariables>({
		retry: false,
		...options,
		mutationFn: async (variables) => {
			setResult(null);

			const response = await mutationFn({ format: null }, variables);

			if (!response.ok) {
				throw response.error;
			}

			const stream = events(response);

			let value = "";

			for await (const event of stream) {
				value += event.data;
				setResult(value);
			}

			return value;
		},
	});

	return useMemo(
		() => [deferredResult, mutation] as const,
		[deferredResult, mutation.status, mutation.submittedAt, mutation.data],
	);
}
