import {
	IonButtons,
	IonChip,
	IonHeader,
	IonIcon,
	IonPage,
	IonProgressBar,
	IonRefresher,
	IonRefresherContent,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Image_nl from "@/assets/nl/explain-friend-check@2x.png";
import { FriendCheckFilledCard } from "@/components/FriendCheck/FilledCard";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { getBasePath } from "@/lib/backend/getBasePath";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { Clipboard } from "@/lib/capacitor/Clipboard";
import { Share } from "@/lib/capacitor/Share";
import { Toast } from "@/lib/capacitor/Toast";
import { FRIEND_CHECK_TARGET } from "@/lib/constants";
import {
	clipboardDocumentIcon,
	inboxIcon,
	questionMarkCircleIcon,
} from "@/lib/icons/@heroicons/react/24/outline";
import { queryOptions_canShare } from "@/lib/query/functions/@capacitor/share/canShare";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function FriendCheck() {
	const { t, i18n } = useTranslation();
	const presentModal = useSelectorModalStore.use.present();
	const [myData] = useMyData();
	const [myProgression] = useMyProgression();
	const [me] = useMe();
	const query = useQuery(queryOptions_friendCheckSubmissions());
	const queryCanShare = useQuery(queryOptions_canShare());
	const queryFriendCheckEnabled = useExtractedQuery({
		queryKey: ["friend-check", "enabled"],
		queryFn: (context) =>
			backendService.friendcheck.friendcheckControllerGetFriendCheckEnabled({
				signal: context.signal,
			}),
	});
	const queryFriendHash = useExtractedQuery({
		queryKey: ["friend-check", "friend-hash"],
		queryFn: (context) =>
			backendService.friendcheck.friendcheckControllerGetFriendHash({
				signal: context.signal,
				format: "text",
			}),
		enabled: !!queryFriendCheckEnabled.data,
	});

	const link =
		!queryFriendHash.data || !me?.friendId
			? undefined
			: getBasePath(
					Routes.SharedFriendCheck({
						id: me.friendId,
						hash: queryFriendHash.data,
					}),
					{ withScheme: false },
				);

	async function shareSheet() {
		await Share.share({
			dialogTitle: t("friend-check.share.title"),
			title: t("friend-check.share.title"),
			text: `${t("friend-check.share.text", {
				link,
				sender_name: me?.name,
			})}`,
		});
	}

	async function copyUrl() {
		await Clipboard.write({ url: link });
		await Toast.show({ text: t("copied-to-clipboard") });
	}

	return (
		<IonPage>
			{!myData.modal.friendCheckExplainer.seen ? (
				<Content className="part-scroll:justify-center">
					<img
						src={{ nl: Image_nl }[i18n.language]}
						className="mx-auto max-h-[50vmin] max-w-[50vmin]"
					/>

					<div className="flex flex-col items-center gap-8">
						<div className="flex flex-col items-center gap-1">
							<IonText className="text-center text-3xl font-bold text-brown-700">
								{t("friend-check.empty-page.title")}
							</IonText>
							<IonText className="text-pretty text-center text-lg text-brown-600">
								{t("friend-check.empty-page.message")}
							</IonText>
						</div>
						<Button
							data-attr="page.app.friend-check.empty.action"
							expand="block"
							className="w-full text-lg font-bold sm:max-w-56"
							onClick={() => presentModal("friendCheckExplainer")}
						>
							{t("friend-check.empty-page.action")}
						</Button>
					</div>
				</Content>
			) : (
				<>
					<IonHeader>
						<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
							<IonTitle className="text-lg font-bold ion-text-brown-700">
								{t("friend-check.name")}
							</IonTitle>

							<IonButtons collapse slot="primary">
								<Button
									data-attr="page.app.friend-check.help"
									shape="round"
									color="light"
									size="small"
									className="min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
									onClick={() => presentModal("friendCheckExplainer")}
								>
									<IonIcon slot="icon-only" icon={questionMarkCircleIcon} />
								</Button>
							</IonButtons>
						</Toolbar>
					</IonHeader>

					<Content className="part-scroll:gap-4">
						<IonHeader collapse="condense" className="-mt-[--padding-top]">
							<IonToolbar className="ion-px-0">
								<IonTitle className="p-0 text-start text-3xl font-bold ion-text-brown-700">
									{t("friend-check.name")}
								</IonTitle>

								<IonButtons collapse slot="primary">
									<Button
										data-attr="page.app.friend-check.help"
										shape="round"
										color="light"
										size="small"
										className="min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
										onClick={() => presentModal("friendCheckExplainer")}
									>
										<IonIcon slot="icon-only" icon={questionMarkCircleIcon} className="size-5" />
									</Button>
								</IonButtons>
							</IonToolbar>
						</IonHeader>

						<IonRefresher
							slot="fixed"
							onIonRefresh={(event) => query.refetch().then(() => event.detail.complete())}
						>
							<IonRefresherContent />
						</IonRefresher>

						<div className="flex flex-col gap-2">
							<IonText className="text-xl font-bold text-brown-600">
								{t("friend-check.answers")}
							</IonText>

							<div className="flex flex-col gap-6">
								<div className="flex flex-col gap-4 rounded-2xl border-2 border-solid border-brown-300 p-4">
									<div className="flex w-full flex-col items-center gap-2">
										{(myProgression.friend_check === "NOT_STARTED" ||
											myProgression.friend_check === "STARTED") && (
											<IonIcon icon={inboxIcon} className="mt-6 size-14 text-brown-400" />
										)}

										{!query.data || query.data.length === 0 ? (
											<div className="flex flex-col items-center text-center text-brown-600">
												<IonText className="font-bold">
													{t("friend-check.start-sharing.title")}
												</IonText>
												<IonText>
													{t("friend-check.start-sharing.text")}{" "}
													{(query.data?.length ?? 0) < FRIEND_CHECK_TARGET && (
														<IonText>
															{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
														</IonText>
													)}
												</IonText>
											</div>
										) : (
											<div className="flex w-full flex-col items-center gap-4 text-center text-brown-600">
												{!!queryFriendCheckEnabled.data && (
													<>
														<IonChip
															disabled
															outline
															className="flex w-full max-w-full flex-row gap-2 border-2 border-brown-300 px-3 py-1 opacity-100 mobile-sm:w-1/2 mobile-sm:min-w-32"
														>
															<IonProgressBar
																className="h-2 rounded-full ion-bg-brown-300 ion-progress-bg-brown-500"
																value={query.data.length / FRIEND_CHECK_TARGET}
															/>
															<IonText className="text-xs font-medium tabular-nums text-brown-700">
																{query.data.length}/{FRIEND_CHECK_TARGET}
															</IonText>
														</IonChip>
														{(query.data?.length ?? 0) < FRIEND_CHECK_TARGET && (
															<IonText>
																{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
															</IonText>
														)}
													</>
												)}
												<div className="flex w-full flex-col gap-3 *:h-[4.5rem]">
													{query.data.map((friendCheckSubmission) => (
														<FriendCheckFilledCard
															key={friendCheckSubmission.id}
															data={friendCheckSubmission}
															withUnseen
															routerDirection="forward"
															routerLink={Routes.FriendCheckSubmission({
																id: friendCheckSubmission.id,
															})}
														/>
													))}
													{Array.from({
														length: Math.max(0, FRIEND_CHECK_TARGET - (query.data ?? []).length),
													}).map((_, index) => (
														<div
															key={index}
															className="rounded-2xl border-2 border-dashed border-brown-300 px-4 py-3"
														/>
													))}
												</div>
											</div>
										)}
									</div>
									{!!query.data && query.data.length === 0 && !!link && (
										<div className="flex w-full flex-col gap-2">
											<Button
												data-attr="page.app.friend-check.action.copy"
												fill="clear"
												color="secondary"
												className="rounded-2xl !bg-brown-100 text-lg"
												expand="block"
												onClick={() => copyUrl()}
											>
												<IonText data-mask-text className="w-full truncate text-start leading-7">
													{link}
												</IonText>
												<IonIcon slot="end" icon={clipboardDocumentIcon} />
											</Button>
											{!!queryCanShare.data && (
												<Button
													data-attr="page.app.friend-check.action.share"
													className="text-lg font-bold"
													expand="block"
													onClick={() => shareSheet()}
												>
													{t("friend-check.share-link")}
												</Button>
											)}
										</div>
									)}
								</div>

								{!!query.data &&
									query.data.length > 0 &&
									!!link &&
									(myProgression.friend_check === "NOT_STARTED" ||
										myProgression.friend_check === "STARTED") && (
										<div className="flex flex-col gap-4 rounded-2xl border-2 border-solid border-brown-300 p-4">
											<div className="flex flex-col items-center text-center text-brown-600">
												<IonText className="font-bold">
													{t("friend-check.start-sharing.again")}
												</IonText>
												<IonText>
													{t("friend-check.start-sharing.text")}{" "}
													{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
												</IonText>
											</div>

											<div className="flex w-full flex-col gap-2">
												<Button
													data-attr="page.app.friend-check.action.copy"
													fill="clear"
													color="secondary"
													className="rounded-2xl !bg-brown-100 text-lg"
													expand="block"
													onClick={() => copyUrl()}
												>
													<IonText data-mask-text className="w-full truncate text-start leading-7">
														{link}
													</IonText>
													<IonIcon slot="end" icon={clipboardDocumentIcon} />
												</Button>
												{!!queryCanShare.data && (
													<Button
														data-attr="page.app.friend-check.action.share"
														className="text-lg font-bold"
														expand="block"
														onClick={() => shareSheet()}
													>
														{t("friend-check.share-link")}
													</Button>
												)}
											</div>
										</div>
									)}
							</div>
						</div>
					</Content>
				</>
			)}
		</IonPage>
	);
}
