import { useMemo } from "react";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryOptions_coreValues = () =>
	queryOptions({
		queryKey: ["core-values", "all"] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetAllCoreValues({ signal: context.signal }),
			),
		staleTime: Infinity,
		retry: false,
		retryOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

export function useCoreValues(options?: Partial<ReturnType<typeof queryOptions_coreValues>>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({
		...queryOptions_coreValues(),
		enabled: isAuthenticated,
		...options,
	});
	const coreValues = query.data;

	return useMemo(
		() => [coreValues, query] as const,
		[coreValues, query.status, query.dataUpdatedAt, query.data],
	);
}
