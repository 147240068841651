import { IonApp } from "@ionic/react";
import { Content } from "@/components/Global/Content";
import { Loading } from "@/components/Loading";
import { SplashScreen } from "@/lib/capacitor/SplashScreen";
import { MATCH_PREFERS_COLOR_SCHEME_DARK } from "@/lib/constants";
import { useAuthStore } from "@/lib/store/auth";
import {
	enforceColorScheme,
	usePreferencesStore,
	useSelectorPreferencesStore,
} from "@/lib/store/preferences";
import { useStoreHydration } from "@/lib/store/utils/useStoreHydration";
import { Compose } from "@/utils/components/Compose";
import { useMediaQuery } from "@/utils/hooks/useMediaQuery";

export function StoreProvider(props: React.PropsWithChildren) {
	return (
		<Compose
			providers={[<ColorSchemeProvider key="color-scheme" />, <HydrationGate key="hydration" />]}
		>
			{props.children}
		</Compose>
	);
}

function ColorSchemeProvider(props: React.PropsWithChildren) {
	const colorscheme = useSelectorPreferencesStore.use.colorScheme();
	const prefersDark = useMediaQuery(MATCH_PREFERS_COLOR_SCHEME_DARK);

	if (colorscheme === "system") {
		enforceColorScheme(prefersDark ? "dark" : "light");
	}

	return props.children;
}

function HydrationGate(props: React.PropsWithChildren) {
	const authHydrated = useStoreHydration(useAuthStore);
	const preferencesHydrated = useStoreHydration(usePreferencesStore);

	const isHydrated = authHydrated && preferencesHydrated;

	if (!isHydrated) {
		SplashScreen.show();

		return (
			// @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382
			<IonApp>
				<Content fullscreen className="part-scroll:items-center part-scroll:justify-center">
					<Loading />
				</Content>
			</IonApp>
		);
	}

	SplashScreen.hide();

	return props.children;
}
