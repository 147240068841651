import { IonButtons, IonFooter, IonIcon, IonModal, IonText } from "@ionic/react";
import { useDeferredValue, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoHeight from "embla-carousel-auto-height";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import Image_1_nl from "@/assets/app/explainer/nl/1@2x.png";
import Image_2_nl from "@/assets/app/explainer/nl/2@2x.png";
import Image_3_nl from "@/assets/app/explainer/nl/3@2x.png";
import Image_4_nl from "@/assets/app/explainer/nl/4@2x.png";
import Image_5_nl from "@/assets/app/explainer/nl/5@2x.png";
import Image from "@/assets/splash_icon.svg";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { fadeAnimation } from "@/components/Modal/animations";
import {
	Carousel,
	type CarouselApi,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMyData } from "@/lib/query/functions/user/data";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

const MotionButton = motion.create(Button);
const steps = [1, 2, 3, 4, 5] as const;

export function ModalAppExplainer() {
	const { t, i18n } = useTranslation();
	const modalRevealRef = useRef<HTMLIonModalElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const posthog = usePostHog();
	const modal = useSelectorModalStore.use.appExplainer();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, { mutation }] = useMyData();
	const [api, setApi] = useState<CarouselApi>();
	const [scrollProgress, setScrollProgress] = useState(0);
	const deferredScrollProgress = useDeferredValue(scrollProgress);

	function onScroll(api: NonNullable<CarouselApi>) {
		setScrollProgress(api.scrollProgress() * (steps.length - 1));
	}

	useEffect(() => {
		if (!api) {
			return;
		}

		api.on("scroll", onScroll);

		return () => {
			api.off("scroll", onScroll);
		};
	}, [api]);

	useEffect(() => {
		if (!api || !modal.isOpen) {
			return;
		}

		api.reInit();
	}, [api, modal.isOpen]);

	return (
		<>
			<IonModal
				ref={modalRevealRef}
				isOpen={modal.isOpen}
				data-attr="modal.app.explainer.splash"
				onWillPresent={() => {
					present("appExplainer");
					posthog.capture("Modal App Explainer Present");
				}}
				onWillDismiss={() => modalRef.current?.present()}
				onClick={() => modalRevealRef.current?.dismiss()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="cursor-pointer !ion-rounded-none ion-h-full ion-w-full"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-[#F0F7FF] part-background:to-[#FFF0E6] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<img src={Image} className="-m-8 w-1/3 min-w-32 max-w-lg select-none drag-none" />

					<div className="-mt-4 text-pretty text-lg font-bold italic text-brown-700">
						{t("modal.app.explainer.splash.motto")
							.split(" ")
							.map((word, index) => (
								<motion.span
									key={word + index}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.2, delay: 1 + 0.2 * index }}
								>
									{word}{" "}
								</motion.span>
							))}
					</div>

					<motion.span
						className="mt-2 text-xs text-brown-700/50"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5, delay: 3.5 }}
					>
						{t("modal.app.explainer.splash.next")}
					</motion.span>
				</Content>
			</IonModal>

			<IonModal
				ref={modalRef}
				onWillDismiss={() => {
					dismiss("appExplainer");
					posthog.capture("Modal App Explainer Dismiss");
				}}
				onDidDismiss={() => api?.scrollTo(0, true)}
				backdropDismiss={false}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
			>
				<Carousel plugins={[AutoHeight()]} setApi={setApi} className="ion-padding -m-4 pt-12">
					<CarouselContent className="items-start transition-[height] *:*:p-4">
						{steps.map((step) => (
							<CarouselItem key={step}>
								<div className="flex flex-col gap-2">
									<img
										src={
											{
												nl: {
													1: Image_1_nl,
													2: Image_2_nl,
													3: Image_3_nl,
													4: Image_4_nl,
													5: Image_5_nl,
												}[step],
											}[i18n.language]
										}
										alt={t(`modal.app.explainer.step${step}.alt`)}
										className="max-h-[50dvh] w-full flex-1 object-contain"
										onLoad={() => api?.reInit()}
									/>

									<div className="flex flex-col gap-3">
										<IonText className="text-2xl font-semibold text-brown-700">
											{t(`modal.app.explainer.step${step}.title`)}
										</IonText>
										<IonText className="text-base text-brown-600">
											{t(`modal.app.explainer.step${step}.text`)}
										</IonText>
									</div>
								</div>
							</CarouselItem>
						))}
					</CarouselContent>
				</Carousel>

				<IonFooter className="ion-no-border">
					<Toolbar className="ion-bg-brown-200 ion-p-4">
						<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
							{steps.map((_, index) => (
								<button key={index} className="bg-brown-300" onClick={() => api?.scrollTo(index)} />
							))}
							<motion.div
								className="pointer-events-none absolute left-0 z-10 bg-brown-500"
								initial={{ x: "0rem" }}
								animate={{ x: `${deferredScrollProgress * 1.125}rem` }}
								transition={{ type: false }}
							/>
						</div>
						<IonButtons slot="primary" className="relative">
							{steps.map((step, index, self) => (
								<MotionButton
									key={step}
									initial="dynamic"
									animate="dynamic"
									variants={{
										dynamic: {
											opacity: 1 - Math.abs(deferredScrollProgress - index),
											zIndex: Math.floor((1 - Math.abs(deferredScrollProgress - index)) * 10),
										},
									}}
									transition={{ type: false }}
									className={cx(
										"!ion-rounded-2xl !ion-px-5 !ion-py-3 part-native:text-lg part-native:font-bold part-native:shadow-xs",
										step === 1 || step === 5
											? "ion-bg-orange-500 ion-text-white"
											: "ion-bg-brown-100 ion-text-brown-700",
										step !== self.length - 1 && "absolute right-0",
									)}
									data-attr={`modal.app.explainer.step${step}.next`}
									onClick={() => {
										if (api?.canScrollNext()) {
											api.scrollNext();
										} else {
											mutation.mutate((draft) => void (draft.modal.appExplainer.seen = true));
											posthog.capture("Modal App Explainer Finished");
											modalRef.current?.dismiss();
										}
									}}
								>
									{t(`modal.app.explainer.step${step}.next`)}
									<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
								</MotionButton>
							))}
						</IonButtons>
					</Toolbar>
				</IonFooter>
			</IonModal>
		</>
	);
}
