import { useEffect } from "react";
import { PostHogProvider as Provider } from "posthog-js/react";
import { getFullName } from "@/lib/getFullName";
import { posthog } from "@/lib/posthog";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { usePreferencesStore } from "@/lib/store/preferences";

export function PostHogProvider(props: React.PropsWithChildren) {
	const [me] = useMe();
	const { language, colorScheme, reducedHaptics } = usePreferencesStore();
	const preferences = { language, colorScheme, reducedHaptics };
	const [progression] = useMyProgression();

	useEffect(() => {
		if (!me) {
			return;
		}

		posthog.identify(me.id, {
			email: me.email,
			emailVerified: me.emailVerified,
			name: getFullName(me),
			image: me.avatarURL,
			authSource: me.authSource,
			friendId: me.friendId,
			...Object.entries(preferences).reduce(
				(acc, [key, value]) => ({
					...acc,
					[`preferences_${key}`]: value,
				}),
				{},
			),
			...Object.entries(progression).reduce(
				(acc, [key, value]) => ({
					...acc,
					[`progression_${key}`]: value,
				}),
				{},
			),
		});
	}, [posthog, me, preferences, progression]);

	return <Provider client={posthog} {...props} />;
}
