import { env } from "@/env";
import { Preferences } from "@/lib/capacitor/Preferences";
import { useAuthStore } from "@/lib/store/auth";
import { logger } from "@/logger";
import { Api, type ApiConfig } from "./api";
import { createFetch } from "./utils/fetch";

type SecurityData = { accessToken?: string };

const sharedOptions: ApiConfig<SecurityData> = {
	securityWorker: (securityData) => ({
		headers: {
			...(!!securityData?.accessToken && { Authorization: `Bearer ${securityData.accessToken}` }),
		},
	}),
	customFetch: createFetch({
		skip: (context) => {
			const url = new URL(context.response.url);
			return url.pathname.startsWith("/auth") || url.pathname.startsWith("/friendcheck/public");
		},
		refresh: async () =>
			backendService.auth.authControllerRefresh({
				secure: false,
				format: "text",
				headers: {
					Authorization: `Bearer ${(JSON.parse((await Preferences.get("auth")).value ?? "{}") as any)?.state?.refreshToken}`,
				},
			}),
		transform: (response) => ({ accessToken: response.data }),
		authenticate: (response) =>
			useAuthStore.getState().authenticate({ accessToken: response.data }),
		unauthenticate: () => useAuthStore.getState().unauthenticate(),
		logger: logger.getLogger("fetch"),
	}),
};

export const backendService = new Api<SecurityData>({
	baseUrl: env.VITE_BACKEND_URL,
	...sharedOptions,
});
