import { createContext, useContext, useRef } from "react";
import { createStore } from "zustand";

export interface ChatStates {
	isResponding?: boolean;
	streamAssistantMessage?: string | null;
	streamUserMessage?: string | null;
}

export interface ChatActions {
	/**
	 * Controls if the stream messages are showing
	 *
	 * @param isResponding Is the LLM currently responding
	 */
	setResponding: (isResponding: boolean) => void;

	/** @param message Temporary bot message while streaming */
	setStreamAssistantMessage: (message: string | null) => void;

	/**
	 * @param message Temporary user message while streaming
	 * @param isResponding Optional parameter to update isResponding (leave undefined to keep the
	 *   current state)
	 */
	setStreamUserMessage: (message: string, isResponding?: boolean) => void;
}

type ChatState = ChatStates & ChatActions;
type ChatStore = ReturnType<typeof createChatStore>;

function createChatStore(options: ChatStates) {
	return createStore<ChatState>()((set) => ({
		...options,

		setResponding: (isResponding) => set(() => ({ isResponding })),
		setStreamAssistantMessage: (message) => set(() => ({ streamAssistantMessage: message })),
		setStreamUserMessage: (message, isResponding?: boolean) =>
			set((state) => ({
				streamUserMessage: message,
				isResponding: isResponding ?? state.isResponding,
			})),
	}));
}

export const ChatContext = createContext<ChatStore | null>(null);

export function useChat(options?: ChatStates) {
	const store = useRef(createChatStore(options || {})).current;
	return store;
}

export function useChatStore(store: ChatStore) {
	return store.getState();
}

export function useChatContext() {
	const store = useContext(ChatContext);
	if (!store) throw new Error("Missing ChatProvider in the tree");

	return store.getState();
}

export function ChatProvider({
	children,
	chatStore,
}: {
	children: React.ReactNode;
	chatStore: ChatStore;
}) {
	return <ChatContext.Provider value={chatStore}>{children}</ChatContext.Provider>;
}
