import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_reflectionQuestion = (
	params: Parameters<typeof backendService.reflection.reflectionControllerGetReflectionById>[0],
) =>
	queryOptions({
		queryKey: ["reflection", params] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
					signal: context.signal,
				}),
			),
	});
