import { useIonViewDidEnter } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useChatContext } from "./Chat";
import { ChatMessage } from "./ChatMessage";

export function ChatContent({ children }: { children?: React.ReactNode }) {
	const chat = useChatContext();
	const messagesEnd = useRef<HTMLDivElement>(null);

	useIonViewDidEnter(() => messagesEnd.current?.scrollIntoView());

	useEffect(() => {
		messagesEnd.current?.scrollIntoView();
	}, [children]);

	return (
		<div className="flex flex-col gap-4">
			{children}

			{chat.isResponding && chat.streamUserMessage && (
				<ChatMessage sender="user">{chat.streamUserMessage}</ChatMessage>
			)}
			{chat.isResponding && <ChatMessage sender="bot">{chat.streamAssistantMessage}</ChatMessage>}
			<div ref={messagesEnd} />
		</div>
	);
}
