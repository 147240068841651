import { IonIcon, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/Global/Button";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";
import { DashboardCoreValuesWidgetStack as Stack } from "./parts/Stack";

export function DashboardCoreValuesWidget(props: React.ComponentPropsWithoutRef<"div">) {
	const { t } = useTranslation();

	const query = useQuery(queryOptions_coreValuesChosen());

	return (
		<div {...props} className={cx("flex flex-col gap-1 py-2", props.className)}>
			<div className="flex flex-row items-center justify-between gap-4">
				<IonText className="text-xl font-bold text-brown-600">
					{t("core-values.widget.title")}
				</IonText>

				<Button
					data-attr="dashboard.core-values-widget.action"
					fill="clear"
					className="-mx-4 w-fit text-sm font-semibold text-brown-600"
					routerLink={Routes.CoreValues()}
					routerDirection="root"
				>
					{t("core-values.widget.action")}

					<IonIcon slot="end" icon={arrowRightIcon} className="size-4 text-brown-400" />
				</Button>
			</div>

			<Stack isLoading={query.isLoading} data={query.data ?? []} />
		</div>
	);
}
