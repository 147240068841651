import posthog from "posthog-js";
import { env } from "@/env";
import { debugValue } from "@/env/utils";
import { Sentry } from "@/lib/sentry";

if (env.VITE_POSTHOG_KEY) {
	posthog.init(env.VITE_POSTHOG_KEY, {
		api_host: env.VITE_POSTHOG_HOST,
		person_profiles: "always",
		persistence: "localStorage",
		debug: debugValue("posthog"),
		capture_pageview: false,
		capture_pageleave: true,
		disable_web_experiments: false,
		session_recording: {
			maskAllInputs: true,
			maskTextSelector: "[data-mask-text], .ph-no-capture",
		},
		before_send: (result) => {
			if (result?.properties["$current_url"]) {
				result.properties["$current_url"] = maskedSensitiveParams(
					result.properties["$current_url"],
				);
			}
			return result;
		},
	});

	if (!!env.VITE_SENTRY_PROJECT_ID && !!env.VITE_SENTRY_ORG && !!env.VITE_SENTRY_URL) {
		Sentry.addIntegration(
			posthog.sentryIntegration({
				organization: env.VITE_SENTRY_ORG,
				projectId: env.VITE_SENTRY_PROJECT_ID,
				prefix: `${env.VITE_SENTRY_URL}/organizations/`,
			}),
		);
	}
}

export * from "posthog-js";

function maskedSensitiveParams(url: string) {
	// Short circuit if no params are detected
	if (url.indexOf("?") === -1) {
		return url;
	}

	const queryString = url.split("?")[1];
	const searchParams = new URLSearchParams(queryString);

	// Sensitive parameters to be hidden
	const sensitiveParams = [
		"accessToken",
		"access_token",
		"refreshToken",
		"refresh_token",
		"verificationCode",
		"verification_code",
		"email",
	];

	// Replace each sensitive parameter's value with '{hidden}'
	sensitiveParams.forEach((param) => {
		if (searchParams.has(param)) {
			searchParams.set(param, "{hidden}");
		}
	});

	// Combine the base URL with the modified query string
	return url.split("?")[0] + "?" + searchParams.toString();
}
