import { IonIcon, IonModal, IonText } from "@ionic/react";
import { Clipboard } from "@capacitor/clipboard";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "@/components/Global/Button";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { clipboardDocumentIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalImpolitic() {
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.integrationsImpolitic();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const posthog = usePostHog();

	const query = useExtractedQuery({
		queryKey: ["integrations", "impolitic", "share-code"] as const,
		queryFn: (context) =>
			backendService.corevalues.coreValuesControllerGenerateShareCode({ signal: context.signal }),
		enabled: modal.isOpen,
	});

	const code = query.data?.code;

	async function handleCopy() {
		if (!code) {
			return;
		}

		await Clipboard.write({ string: code });

		await Toast.show({ text: t("copied-to-clipboard") });
	}

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("integrationsImpolitic");
				posthog.capture("Modal Integrations Impolitic Present");
			}}
			onWillDismiss={() => {
				dismiss("integrationsImpolitic");
				posthog.capture("Modal Integrations Impolitic Dismiss");
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				<div className="flex flex-col gap-2">
					<IonText className="text-2xl font-bold text-brown-700">
						{t("integrations.impolitic.name")}
					</IonText>
					<IonText className="text-base text-brown-600">
						{t("integrations.impolitic.explanation")}
					</IonText>
				</div>

				<div className="flex items-center gap-4 rounded-xl bg-brown-100 p-4">
					<IonText className="flex-1 text-center text-2xl font-bold text-brown-700">
						{code || `${t("loading")}...`}
					</IonText>
					<Button
						data-attr="modal.integrations.impolitic.copy"
						onClick={handleCopy}
						size="small"
						fill="clear"
						className="aspect-square shrink-0 ion-bg-brown-200 ion-text-brown-700"
					>
						<IonIcon icon={clipboardDocumentIcon} className="size-6" />
					</Button>
				</div>

				<Button
					data-attr="modal.integrations.impolitic.dismiss"
					expand="block"
					onClick={() => modalRef.current?.dismiss()}
				>
					{t("close")}
				</Button>
			</div>
		</IonModal>
	);
}
