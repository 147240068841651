import { z } from "zod";
import { type DebugOption, env } from "./";

export function debugValue(option: DebugOption): boolean {
	return env.VITE_DEBUG.includes(option);
}

export function boolean() {
	return (
		z
			.string()
			// transform to boolean using preferred coercion logic
			.transform((string) => string !== "false" && string !== "0")
	);
}
