import type { JSX } from "react";
import { type ModalKeys } from "@/lib/store/modal";
import { ModalCropProfilePicture } from "./App/CropProfilePicture";
import { ModalDeleteAccount } from "./App/DeleteAccount";
import { ModalAppExplainer } from "./App/Explainer";
import { ModalPushNotificationsDenied } from "./App/PushNotificationsDenied";
import { ModalCoreValuesCompileExplainer } from "./CoreValues/Compile/Explainer";
import { ModalCoreValuesCompileGeneral } from "./CoreValues/Compile/General";
import { ModalCoreValuesDetail } from "./CoreValues/Detail";
import { ModalCoreValuesPersonalizeCompleted } from "./CoreValues/Personalize/Completed";
import { ModalCoreValuesPersonalizeExplainer } from "./CoreValues/Personalize/Explainer";
import { ModalDevDeleteFriendcheck } from "./Dev/DeleteFriendcheck";
import { ModalFriendCheckAnalysisGenerate } from "./FriendCheck/Analysis/Generate";
import { ModalFriendCheckAnalysisView } from "./FriendCheck/Analysis/View";
import { ModalFriendCheckCompleted } from "./FriendCheck/Completed";
import { ModalFriendCheckExplainer } from "./FriendCheck/Explainer";
import { ModalImpolitic } from "./Integrations/Impolitic";
import { ModalReflectionAnalysisGenerate } from "./Reflection/Analysis/Generate";
import { ModalReflectionAnalysisView } from "./Reflection/Analysis/View";
import { ModalReflectionCompleted } from "./Reflection/Completed";
import { ModalReflectionExplainerFirstQuestion } from "./Reflection/Explainer/FirstQuestion";
import { ModalReflectionExplainerGeneral } from "./Reflection/Explainer/General";
import { ModalReflectionQuestionCompleted } from "./Reflection/Question/Completed";
import { ModalReflectionQuestionFirstAnswer } from "./Reflection/Question/FirstAnswer";

export function ModalProvider() {
	return (
		<>
			{Object.entries({
				appCropProfilePicture: ModalCropProfilePicture,
				appDeleteAccount: ModalDeleteAccount,
				appExplainer: ModalAppExplainer,
				appPushNotificationsDenied: ModalPushNotificationsDenied,
				coreValuesCompileExplainer: ModalCoreValuesCompileExplainer,
				coreValuesCompileGeneral: ModalCoreValuesCompileGeneral,
				coreValuesDetail: ModalCoreValuesDetail,
				coreValuesPersonalizeCompleted: ModalCoreValuesPersonalizeCompleted,
				coreValuesPersonalizeExplainer: ModalCoreValuesPersonalizeExplainer,
				devDeleteFriendcheck: ModalDevDeleteFriendcheck,
				friendCheckAnalysisGenerate: ModalFriendCheckAnalysisGenerate,
				friendCheckAnalysisView: ModalFriendCheckAnalysisView,
				friendCheckCompleted: ModalFriendCheckCompleted,
				friendCheckExplainer: ModalFriendCheckExplainer,
				integrationsImpolitic: ModalImpolitic,
				reflectionAnalysisGenerate: ModalReflectionAnalysisGenerate,
				reflectionAnalysisView: ModalReflectionAnalysisView,
				reflectionCompleted: ModalReflectionCompleted,
				reflectionExplainerFirstQuestion: ModalReflectionExplainerFirstQuestion,
				reflectionExplainerGeneral: ModalReflectionExplainerGeneral,
				reflectionQuestionCompleted: ModalReflectionQuestionCompleted,
				reflectionQuestionFirstAnswer: ModalReflectionQuestionFirstAnswer,
			} satisfies Record<ModalKeys, () => JSX.Element>).map(([key, Component]) => (
				<Component key={key} />
			))}
		</>
	);
}
