import Color from "color";
import { type ChosenCoreValueItem } from "@/lib/backend/api";

export function CoreValueCard({
	coreValue,
}: {
	coreValue: Pick<ChosenCoreValueItem, "customName" | "coreValue" | "description" | "reason">;
}) {
	return (
		<div className="flex flex-col items-start gap-2 rounded-2xl bg-white p-4">
			<div className="flex items-center gap-2">
				<div
					style={{
						backgroundColor: Color(coreValue?.coreValue.color).alpha(0.25).rgb().toString(),
					}}
					className="flex h-8 w-8 items-center justify-center rounded-full py-1 text-center"
				>
					{coreValue?.coreValue.emoji}
				</div>
				<div
					style={{
						backgroundColor: Color(coreValue?.coreValue.color).alpha(0.25).rgb().toString(),
					}}
					className="flex h-8 items-center rounded-full px-2.5 text-sm font-semibold"
				>
					<span
						className="brightness-50"
						style={{ color: Color(coreValue?.coreValue.color).darken(0.625).rgb().toString() }}
					>
						{coreValue?.customName || coreValue?.coreValue.name}
					</span>
				</div>
			</div>
			<p data-mask-text>{coreValue?.description || coreValue?.reason}</p>
		</div>
	);
}
