import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Capacitor } from "@/lib/capacitor";
import { Device } from "@/lib/capacitor/Device";
import { PushNotifications } from "@/lib/capacitor/PushNotifications";
import { useMe } from "@/lib/query/functions/user/me";
import { logger } from "@/logger";

export function NotificationListenerPush() {
	const queryPermissionState = useQuery({
		queryKey: ["push-notification", "permission"],
		queryFn: PushNotifications.checkPermissions,
		initialData: "prompt" satisfies PermissionState as PermissionState,
		enabled: Capacitor.Platform !== "web",
	});
	const permissionState = queryPermissionState.data;

	const [me] = useMe();
	const settings = me?.settings;

	const mutationAddDevice = useExtractedMutation({
		mutationKey: ["notification", "add-device"],
		mutationFn: backendService.notification.notificationControllerAddDevice,
	});

	useEffect(() => {
		PushNotifications.addRegistrationListener((token) => {
			logger.debug({ token }, "Push Registration");

			Device.getId().then((id) => mutationAddDevice.mutate({ name: id, token }));
		});

		PushNotifications.addRegistrationErrorListener((error) =>
			logger.error({ error }, "Push Notification Registration Error"),
		);

		PushNotifications.addPushNotificationReceivedListener((notification) =>
			logger.info({ notification }, "Push Notification Received"),
		);

		PushNotifications.addPushNotificationActionPerformedListener((notification) => {
			logger.info({ notification }, "Push Notification Action Performed");
		});

		return () => {
			PushNotifications.removeAllListeners();
		};
	}, []);

	if (
		// Platform is not web
		Capacitor.Platform === "web" ||
		// Settings have loaded
		!settings ||
		// Permission to set push notifications
		permissionState !== "granted"
	) {
		return null;
	}

	return null;
}
