import { IonButtons, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { CoreValueProgress } from "@/components/CoreValue/Progress";
import { DashboardCoreValuesWidget as CoreValuesWidget } from "@/components/Dashboard/CoreValuesWidget";
import { DashboardFriendCheckWidget as FriendCheckWidget } from "@/components/Dashboard/FriendCheckWidget";
import { DashboardReflectionWidget as ReflectionWidget } from "@/components/Dashboard/ReflectionWidget";
import { FriendCheckProgress } from "@/components/FriendCheck/Progress";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionProgress } from "@/components/Reflection/Progress";
import { cog6ToothIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { queryOptions_coreValuesAnalysis } from "@/lib/query/functions/core-values/analysis";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function Dashboard() {
	const { t } = useTranslation();
	const [me] = useMe();
	const [myProgression] = useMyProgression();
	const [myData] = useMyData();
	const presentModal = useSelectorModalStore.use.present();

	const queryCoreValuesReflection = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "reflection" }),
		enabled: myProgression?.reflection === "ANALYSED",
	});

	const queryCoreValuesFriendCheck = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "friendcheck" }),
		enabled:
			myProgression.friend_check === "ANALYSED" || myProgression.friend_check === "SUMMARIZED",
	});

	const coreValues = [
		...(queryCoreValuesReflection.data?.map((coreValue) => ({
			type: "reflection" as const,
			...coreValue,
		})) ?? []),
		...(queryCoreValuesFriendCheck.data
			?.filter((coreValue) => myData.coreValues.savedFriendCheck.includes(coreValue.id))
			.map((coreValue) => ({
				type: "friend-check" as const,
				...coreValue,
			})) ?? []),
	].sort((a, b) => (a.score > b.score ? -1 : 1));

	const queryCoreValuesChosen = useQuery({
		...queryOptions_coreValuesChosen(),
		enabled: myProgression.core_values === "SUMMARIZED",
	});

	return (
		<IonPage>
			<IonHeader>
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonTitle data-mask-text className="text-lg font-bold ion-text-brown-700">
						{t("dashboard.title", { name: me?.name })}
					</IonTitle>

					<IonButtons collapse slot="primary">
						<Button
							data-attr="page.app.dashboard.settings"
							routerLink={Routes.Settings()}
							routerDirection="forward"
							shape="round"
							color="light"
							size="small"
							className="min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
						>
							<IonIcon slot="icon-only" icon={cog6ToothIcon} className="size-5" />
						</Button>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content className="part-scroll:gap-8">
				<IonHeader collapse="condense" className="-mt-[--padding-top]">
					<IonToolbar className="ion-px-0">
						<IonTitle
							data-mask-text
							className="p-0 pr-12 text-start text-3xl font-bold ion-text-brown-700"
						>
							{t("dashboard.title", { name: me?.name })}
						</IonTitle>

						<IonButtons collapse slot="primary">
							<Button
								data-attr="page.app.dashboard.settings"
								routerLink={Routes.Settings()}
								routerDirection="forward"
								shape="round"
								color="light"
								size="small"
								className="min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							>
								<IonIcon icon={cog6ToothIcon} className="size-5" />
							</Button>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<CoreValueAvatarWithBackground
					className="py-8"
					coreValues={
						queryCoreValuesChosen.data?.map((coreValue) => coreValue.coreValue) ?? coreValues
					}
				/>

				<div className="space flex flex-col divide-y divide-brown-200 rounded-2xl border border-brown-200 drop-shadow-[0px_4px_24px_0px_theme(colors.black/.25)] contain-paint *:rounded-none *:border-x-0">
					{myProgression.core_values !== "SUMMARIZED" ? (
						<>
							<ReflectionProgress />
							<FriendCheckProgress />
						</>
					) : (
						<>
							<CoreValueProgress />
						</>
					)}
				</div>

				{myProgression?.reflection === "NOT_STARTED" || myProgression?.reflection === "STARTED" ? (
					<ReflectionWidget />
				) : myProgression?.friend_check === "NOT_STARTED" ||
				  myProgression?.friend_check === "STARTED" ? (
					<FriendCheckWidget />
				) : myProgression.reflection === "ANALYSED" &&
				  (myProgression.friend_check === "ANALYSED" ||
						myProgression.friend_check === "SUMMARIZED") &&
				  myProgression.chosen_core_values === "NOT_STARTED" ? (
					<Button
						className="text-lg font-bold !ion-rounded-2xl"
						data-attr="page.app.dashboard.compile-core-values"
						onClick={() => presentModal("coreValuesCompileGeneral")}
					>
						{t("core-values.compile-top-5")}
					</Button>
				) : (
					myProgression.core_values === "SUMMARIZED" && <CoreValuesWidget />
				)}
			</Content>
		</IonPage>
	);
}
