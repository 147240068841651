import { IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonPage } from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { ChatProvider, useChat, useChatStore } from "@/components/Chat/Chat";
import { ChatContent } from "@/components/Chat/ChatContent";
import { ChatFooter } from "@/components/Chat/ChatFooter";
import { ChatInput } from "@/components/Chat/ChatInput";
import { ChatMessage } from "@/components/Chat/ChatMessage";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { MultipleChoiceAnswer } from "@/components/Reflection/MultipleChoiceAnswer";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMutationStream } from "@/lib/query/hooks/useMutationStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { logger } from "@/logger";

export function ReflectionQuestion() {
	const params = Routes.ReflectionQuestion.useParams();
	const posthog = usePostHog();
	const presentModal = useSelectorModalStore.use.present();
	const { t } = useTranslation();

	const chatStore = useChat();

	const chat = useChatStore(chatStore);

	const questionId = params.id || "";

	const queryReflection = useExtractedQuery({
		queryKey: ["reflection", questionId] as const,
		queryFn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!questionId,
		retry: false,
	});

	const reflectionChat = queryReflection.data;
	const chatId = reflectionChat?.id ?? "";

	const [streamedResponse, mutationStream] = useMutationStream({
		mutationKey: ["reflection", "generate"],
		mutationFn: (params) =>
			backendService.reflection.reflectionControllerGenerateReflection(chatId, params),
		onSettled: () => {
			queryReflection.refetch();
			chat.setResponding(false);
		},
	});

	useEffect(() => {
		if (reflectionChat?.messages.length == 0 && !mutationStream.isPending) {
			mutationStream.mutate();
			chat.setResponding(true);
		}
	}, [reflectionChat?.messages.length]);

	if (streamedResponse !== chat.streamAssistantMessage) {
		chat.setStreamAssistantMessage(streamedResponse);
	}

	useEffect(() => {
		if (reflectionChat?.status == "CHAT_COMPLETED") {
			presentModal("reflectionQuestionCompleted", { id: questionId });
			posthog.capture("Reflection Question Completed", { questionId });
		}
	}, [reflectionChat?.status]);

	if (!queryReflection.isLoading && !queryReflection.data && questionId) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
						<IonButtons slot="secondary">
							<IonButton
								data-attr="page.reflection.question.back"
								data-touch-target
								routerLink={Routes.Reflection()}
								routerDirection="back"
								color="light"
								className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							>
								<IonIcon icon={arrowLeftIcon} />
							</IonButton>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.reflection.not-found.title")}
						description={t("page.reflection.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary">
						<IonButton
							data-attr="page.reflection.question.back"
							data-touch-target
							routerLink={Routes.Reflection()}
							routerDirection="back"
							color="light"
							className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<ChatProvider chatStore={chatStore}>
				<Content className="part-scroll:gap-8">
					<ChatContent>
						<ReflectionCard
							onClick={() => {
								if (
									reflectionChat?.status === "CHAT_COMPLETED" ||
									reflectionChat?.status === "FINALIZED"
								) {
									presentModal("reflectionQuestionCompleted", { id: params.id });
								}
							}}
							className="shrink-0 gap-2"
						>
							<strong className="text-pretty pt-2 text-base font-semibold">
								{reflectionChat?.reflection?.initialMessageContent}
							</strong>
							{reflectionChat?.reflection?.initialMessageChoices?.length &&
							reflectionChat?.reflection?.initialMessageChoices?.length > 0 ? (
								<MultipleChoiceAnswer selected={reflectionChat.initialMessageChosen ?? []} />
							) : (
								<p data-ph-no-capture className="text-base font-normal">
									{reflectionChat?.initialMessageAnswer}
								</p>
							)}
						</ReflectionCard>
						{reflectionChat?.messages
							.filter((message) => message.content)
							.map((message, index) => (
								<ChatMessage key={index} sender={message.role === "USER" ? "user" : "bot"}>
									{message.content}
								</ChatMessage>
							))}
					</ChatContent>
				</Content>
				<IonFooter>
					<ChatFooter>
						{reflectionChat?.status === "IN_PROGRESS" && (
							<ChatInput
								onSubmit={async (content) => {
									try {
										await backendService.reflection.reflectionControllerAnswerQuestion(chatId, {
											content: content,
										});
									} catch {
										logger.error("Failed to answer reflection question");
										Toast.show({
											text: t("modal.reflection.explainer.question.error.send-message-failed"),
										});
										return false;
									}

									posthog.capture("Reflection Question Answered", { questionId });

									mutationStream.mutate();

									return true;
								}}
							/>
						)}
					</ChatFooter>
				</IonFooter>
			</ChatProvider>
		</IonPage>
	);
}
