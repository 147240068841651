import { useCallback } from "react";
import { useBoolean, useCounter, useInterval } from "react-use";

type CountdownOptions = {
	countStart: number;

	intervalMs?: number;
	isIncrement?: boolean;

	countStop?: number;
};

type CountdownControllers = {
	startCountdown: () => void;
	stopCountdown: () => void;
	resetCountdown: () => void;
};

export function useCountdown({
	countStart,
	countStop = 0,
	intervalMs = 1000,
	isIncrement = false,
}: CountdownOptions): [number, CountdownControllers] {
	const [count, { inc: increment, dec: decrement, reset: resetCounter }] = useCounter(countStart);

	/*
	 * Note: used to control the useInterval
	 * running: If true, the interval is running
	 * start: Should set running true to trigger interval
	 * stop: Should set running false to remove interval.
	 */
	const [isCountdownRunning, toggleCountdown] = useBoolean(false);

	// Will set running false and reset the seconds to initial value.
	const resetCountdown = useCallback(() => {
		toggleCountdown(false);
		resetCounter();
	}, [toggleCountdown, resetCounter]);

	const countdownCallback = useCallback(() => {
		if (count === countStop) {
			toggleCountdown(false);
			return;
		}

		if (isIncrement) {
			increment();
		} else {
			decrement();
		}
	}, [count, countStop, decrement, increment, isIncrement, toggleCountdown]);

	useInterval(countdownCallback, isCountdownRunning ? intervalMs : null);

	return [
		count,
		{
			startCountdown: () => toggleCountdown(true),
			stopCountdown: () => toggleCountdown(false),
			resetCountdown,
		},
	];
}
