import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function LoadingText() {
	const { t } = useTranslation();

	return (
		<motion.span
			animate={{ opacity: [1, 0.5, 1] }}
			transition={{
				duration: 1,
				repeat: Infinity,
				ease: "easeInOut",
			}}
		>
			{t("loading")}...
		</motion.span>
	);
}
