import { IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { CoreValueRankingRow } from "@/components/CoreValue/RankingRow";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { queryOptions_coreValuesAnalysis } from "@/lib/query/functions/core-values/analysis";
import { useSelectorModalStore } from "@/lib/store/modal";

const MotionButton = motion.create(Button);

export function ModalReflectionAnalysisView() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const contentRef = useRef<HTMLIonContentElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionAnalysisView();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	const queryAllCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: modal.isOpen });
	const queryMyCoreValues = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "reflection" }),
		enabled: modal.isOpen,
	});

	const coreValues = queryMyCoreValues.data?.map((coreValue) => ({
		...coreValue,
		...queryAllCoreValues.data?.find((coreValueItem) => coreValueItem.name === coreValue.name),
	}));

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("reflectionAnalysisView");
				posthog.capture("Modal Reflection Analysis View Present");
			}}
			onWillDismiss={() => {
				dismiss("reflectionAnalysisView");
				posthog.capture("Modal Reflection Analysis View Dismiss");
			}}
		>
			<Content ref={contentRef} fullscreen inModal className="part-scroll:gap-4">
				<CoreValueAvatarWithBackground coreValues={coreValues} />

				<IonText className="text-pretty px-4 py-2 text-center text-brown-700">
					{t("modal.reflection.analysis.view.text")}{" "}
				</IonText>

				<ol className="flex flex-1 flex-col gap-3">
					{coreValues?.map((coreValue, index) => (
						<CoreValueRankingRow
							key={index}
							data-attr="modal.reflection.analysis.core-value"
							data={{ ...coreValue, number: index + 1 }}
							transition={{ duration: 0.25, delay: index * 0.25 }}
							onAnimationComplete={() => contentRef.current?.scrollToBottom()}
							onClick={() => present("coreValuesDetail", { item: coreValue })}
						/>
					))}
				</ol>

				<MotionButton
					data-attr="modal.reflection.analysis.view.close"
					className="mt-auto text-lg font-bold"
					onClick={() => modalRef.current?.dismiss()}
					initial="hidden"
					animate="visible"
					variants={{
						visible: { opacity: 1, pointerEvents: "auto" },
						hidden: { opacity: 0, pointerEvents: "none" },
					}}
					transition={{ delay: 3.5, duration: 0.5 }}
				>
					{t("close")}
				</MotionButton>
			</Content>
		</IonModal>
	);
}
