// sort-imports-ignore
import "@/utils/polyfills/cryptoRandomUUID";
import "@/utils/polyfills/formRequestSubmit";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "@/App";
import { Providers } from "@/components/Providers";
import "@/i18n";
import "@/lib/sentry";
import "@/styles/index.css";
import { getVersion } from "./utils/functions/getVersion";

defineCustomElements(window);

const container = document.getElementById("root");
const root = createRoot(container!);

// eslint-disable-next-line no-restricted-syntax
console.log("Imperfect version:", getVersion());

root.render(
	<StrictMode>
		<Providers>
			<App />
		</Providers>
	</StrictMode>,
);
