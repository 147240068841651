import { type HttpResponse } from "@/lib/backend/api";
import { FetchError, ResponseError, ResponseWithError } from "./error";

export function extractResponseData<TData, TError>(response: HttpResponse<TData, TError>) {
	if (!response.ok) {
		throw new FetchError(response.status, response.statusText, response.error);
	}

	return response.data;
}

export function extractResponseError(error: unknown) {
	if (error instanceof Response) {
		throw new FetchError(error.status, error.statusText, (error as any)?.error);
	}

	const responseWithError = ResponseWithError.safeParse(error);
	if (responseWithError.success) {
		throw responseWithError.data.error;
	}

	const responseError = ResponseError.safeParse(error);
	if (responseError.success) {
		throw responseError.data;
	}

	if (error instanceof FetchError || error instanceof Error) {
		throw error;
	}

	throw error;
}

export async function extractionWrapper<TData, TError>(
	call: HttpResponse<TData, TError> | Promise<HttpResponse<TData, TError>>,
) {
	try {
		return extractResponseData<TData, TError>(await call);
	} catch (error) {
		throw extractResponseError(error);
	}
}
