import { useMemo } from "react";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryOptions_me = () =>
	queryOptions({
		queryKey: ["user", "me"] as const,
		queryFn: (context) =>
			extractionWrapper(backendService.user.usersControllerMe({ signal: context.signal })),
	});

export function useMe(options?: Partial<ReturnType<typeof queryOptions_me>>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({ ...queryOptions_me(), enabled: isAuthenticated, ...options });
	const me = query.data;

	return useMemo(() => [me, query] as const, [me, query.status, query.dataUpdatedAt, query.data]);
}
