import { IonFooter, IonModal, useIonRouter } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { CoreValueCard } from "@/components/CoreValue/CoreValueCard";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalCoreValuesPersonalizeCompleted() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const router = useIonRouter();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.coreValuesPersonalizeCompleted();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	const queryCoreValues = useQuery({ ...queryOptions_coreValuesChosen(), enabled: modal.isOpen });

	const thisCoreValue =
		modal.id && !queryCoreValues.isFetching
			? queryCoreValues.data?.find((coreValue) => coreValue.id === modal.id)
			: null;

	const nextCoreValue = queryCoreValues.data?.find((coreValue) => !coreValue.customName);

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("coreValuesPersonalizeCompleted");
				posthog.capture("Modal CoreValues Personalize Completed Present");
			}}
			onWillDismiss={() => {
				dismiss("coreValuesPersonalizeCompleted", { id: null });
				posthog.capture("Modal CoreValues Personalize Completed Dismiss");
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding -rotate-2 pt-12">
				{!!thisCoreValue && <CoreValueCard coreValue={thisCoreValue} />}
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<div className="flex flex-col gap-2">
						<Button
							data-attr="modal.core-values.personalize.completed.confirm"
							expand="block"
							fill="solid"
							className="text-lg font-bold !ion-rounded-2xl"
							onClick={() => {
								modalRef.current?.dismiss();

								if (nextCoreValue) {
									router.push(
										Routes.CoreValuesChat({ id: nextCoreValue?.id }),
										"forward",
										"replace",
									);
								} else {
									router.push(Routes.CoreValues(), "back", "replace");
								}
							}}
						>
							{nextCoreValue
								? t("modal.core-values.personalize.completed.confirm")
								: t("modal.core-values.personalize.completed.finish")}
						</Button>
						<Button
							data-attr="modal.core-values.personalize.completed.cancel"
							expand="block"
							fill="clear"
							className="text-lg font-bold ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl"
							onClick={() => {
								modalRef.current?.dismiss();
								router.push(Routes.CoreValues(), "back");
							}}
						>
							{t("modal.core-values.personalize.completed.cancel")}
						</Button>
					</div>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
