import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const DEBUG_OPTIONS = [
	"query",
	"store",
	"i18n",
	"logger",
	"ui",
	"ui:touch-target",
	"sentry",
	"posthog",
	"internal-devtools",
] as const;
export type DebugOption = (typeof DEBUG_OPTIONS)[number];

export const env = createEnv({
	/**
	 * The prefix that client-side variables must have. This is enforced both at a type-level and at
	 * runtime.
	 */
	clientPrefix: "VITE_",

	/** Variables from Vite https://vitejs.dev/guide/env-and-mode#env-variables */
	shared: {
		MODE: z.union([z.enum(["development", "production"]), z.coerce.string()]),
		BASE_URL: z.union([z.literal("/"), z.coerce.string()]),
		DEV: z.coerce.boolean(),
		PROD: z.coerce.boolean(),
		SSR: z.coerce.boolean(),
	},

	client: {
		VITE_HOST_URL: z
			.string()
			.url()
			.refine((value) => !value.endsWith("/"), {
				message: "VITE_HOST_URL should not end with a trailing slash",
			}),
		VITE_BACKEND_URL: z
			.string()
			.url()
			.refine((value) => !value.endsWith("/"), {
				message: "VITE_BACKEND_URL should not end with a trailing slash",
			}),
		VITE_DEBUG: z.coerce
			.string()
			.optional()
			.default("")
			.transform((value) => (value.length > 0 ? value.split(",") : []) as DebugOption[])
			.refine(
				(value) => value.length === 0 || value.every((option) => DEBUG_OPTIONS.includes(option)),
			),
		VITE_LOG_LEVEL: z.enum(["trace", "debug", "info", "warn", "error", "silent"]).default("info"),

		/** Options */
		VITE_EOD_REMINDER_ID: z.coerce.number().min(-2147483648).max(2147483648).default(393),
		VITE_EOD_REMINDER_DEFAULT_TIME: z
			.string()
			.regex(/^(?:[01]\d|2[0-3]):[0-5]\d$/, { message: "Invalid time value (00:00-23:59)" })
			.default("21:30"),
		VITE_PASSWORD_MIN_LENGTH: z.coerce.number().min(1).default(8),
		VITE_PASSWORD_REQUIREMENTS: z.coerce.number().min(1).max(4).default(3),

		/** Sentry */
		VITE_SENTRY_URL: z
			.string()
			.url()
			.refine((value) => !value.endsWith("/"), {
				message: "VITE_SENTRY_URL should not end with a trailing slash",
			})
			.optional(),
		VITE_SENTRY_PROJECT_ID: z.coerce.number().finite().optional(),
		VITE_SENTRY_ORG: z.string().optional(),
		VITE_SENTRY_DSN: z.string().url().optional(),
		VITE_SENTRY_ENVIRONMENT: z
			.enum(["localhost", "development", "test", "staging", "production"])
			.optional(),
		VITE_SENTRY_TRACES_SAMPLE_RATE: z.coerce.number().min(0).max(1).step(0.01).default(0.75),
		VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: z.coerce
			.number()
			.min(0)
			.max(1)
			.step(0.01)
			.default(0.25),
		VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE: z.coerce.number().min(0).max(1).step(0.01).default(1),

		// PostHog
		VITE_POSTHOG_KEY: z.string().optional(),
		VITE_POSTHOG_HOST: z
			.string()
			.url()
			.refine((value) => !value.endsWith("/"), {
				message: "VITE_POSTHOG_HOST should not end with a trailing slash",
			})
			.default("https://eu.i.posthog.com"),

		VITE_DEV_EMAIL: z.string().email().optional(),
		VITE_DEV_PASSWORD: z.string().optional(),
		VITE_DEV_RANDOMIZE: z.coerce.boolean().optional(),
	},

	/**
	 * What object holds the environment variables at runtime. This is usually `process.env` or
	 * `import.meta.env`.
	 */
	runtimeEnv: import.meta.env,

	/**
	 * By default, this library will feed the environment variables directly to the Zod validator.
	 *
	 * This means that if you have an empty string for a value that is supposed to be a number (e.g.
	 * `PORT=` in a ".env" file), Zod will incorrectly flag it as a type mismatch violation.
	 * Additionally, if you have an empty string for a value that is supposed to be a string with a
	 * default value (e.g. `DOMAIN=` in an ".env" file), the default value will never be applied.
	 *
	 * In order to solve these issues, we recommend that all new projects explicitly specify this
	 * option as true.
	 */
	emptyStringAsUndefined: true,
});
