import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_myAccess = () =>
	queryOptions({
		queryKey: ["user", "me", "access"] as const,
		queryFn: (context) =>
			extractionWrapper(backendService.user.usersControllerGetMyAccess({ signal: context.signal })),
	});
