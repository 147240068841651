import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_coreValuesAnalysis = (
	params: Parameters<
		typeof backendService.corevalues.coreValuesControllerGetFriendCheckCoreValueAnalysis
	>[0],
) =>
	queryOptions({
		queryKey: ["core-values", "analysis", params] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetFriendCheckCoreValueAnalysis(params, {
					signal: context.signal,
				}),
			),
	});
