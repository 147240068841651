import { merge } from "lodash-es";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { debugValue } from "@/env/utils";
import { Capacitor } from "@/lib/capacitor";
import { createSelectors } from "@/lib/store/utils/createSelectors";

export type ModalKeys =
	| "appCropProfilePicture"
	| "appDeleteAccount"
	| "appExplainer"
	| "appPushNotificationsDenied"
	| "coreValuesCompileExplainer"
	| "coreValuesCompileGeneral"
	| "coreValuesDetail"
	| "coreValuesPersonalizeCompleted"
	| "coreValuesPersonalizeExplainer"
	| "devDeleteFriendcheck"
	| "friendCheckAnalysisGenerate"
	| "friendCheckAnalysisView"
	| "friendCheckCompleted"
	| "friendCheckExplainer"
	| "integrationsImpolitic"
	| "reflectionAnalysisGenerate"
	| "reflectionAnalysisView"
	| "reflectionCompleted"
	| "reflectionExplainerFirstQuestion"
	| "reflectionExplainerGeneral"
	| "reflectionQuestionCompleted"
	| "reflectionQuestionFirstAnswer";

type BaseModalState = { isOpen: boolean };

interface ModalStates extends Record<ModalKeys, BaseModalState> {
	coreValuesDetail: BaseModalState & {
		item: {
			name?: string | null;
			reason?: string | null;
			score?: number | null;
			type?: "friend-check" | "reflection" | null;
		} | null;
	};
	coreValuesPersonalizeCompleted: BaseModalState & { id: string | null };
	reflectionExplainerGeneral: BaseModalState & { onCompleted: VoidFunction | null };
	reflectionExplainerFirstQuestion: BaseModalState & { id: string | null };
	reflectionQuestionCompleted: BaseModalState & { id: string | null };
	reflectionQuestionFirstAnswer: BaseModalState & { id: string | null };
	appCropProfilePicture: BaseModalState & {
		imageUrl: string;
		onCompleted: ((crop: string) => void) | null;
	};
}

type ModalActions = Record<
	"present" | "dismiss",
	<T extends ModalKeys>(key: T, update?: Partial<ModalStates[T]>) => void
>;

export const useModalStore = create<ModalStates & ModalActions>()(
	devtools(
		immer((set) => ({
			appCropProfilePicture: { isOpen: false, imageUrl: "", onCompleted: null },
			appDeleteAccount: { isOpen: false },
			appExplainer: { isOpen: false },
			appPushNotificationsDenied: { isOpen: false },
			coreValuesCompileExplainer: { isOpen: false },
			coreValuesCompileGeneral: { isOpen: false },
			coreValuesDetail: { isOpen: false, item: null },
			coreValuesPersonalizeCompleted: { isOpen: false, id: null },
			coreValuesPersonalizeExplainer: { isOpen: false },
			devDeleteFriendcheck: { isOpen: false },
			friendCheckAnalysisGenerate: { isOpen: false },
			friendCheckAnalysisView: { isOpen: false },
			friendCheckCompleted: { isOpen: false },
			friendCheckExplainer: { isOpen: false },
			integrationsImpolitic: { isOpen: false },
			reflectionAnalysisGenerate: { isOpen: false },
			reflectionAnalysisView: { isOpen: false },
			reflectionCompleted: { isOpen: false },
			reflectionExplainerFirstQuestion: { isOpen: false, id: null },
			reflectionExplainerGeneral: { isOpen: false, onCompleted: null },
			reflectionQuestionCompleted: { isOpen: false, id: null },
			reflectionQuestionFirstAnswer: { isOpen: false, id: null },

			present: <T extends ModalKeys>(key: T, update?: Partial<ModalStates[T]>) =>
				set((state) => {
					state[key].isOpen = true;

					if (typeof update === "object") {
						state[key] = merge(state[key], update);
					}
				}),
			dismiss: <T extends ModalKeys>(key: T, update?: Partial<ModalStates[T]>) =>
				set((state) => {
					state[key].isOpen = false;

					if (typeof update === "object") {
						state[key] = merge(state[key], update);
					}
				}),
		})),
		{
			enabled: debugValue("store") && Capacitor.Platform === "web",
		},
	),
);

export const useSelectorModalStore = createSelectors(useModalStore);
