import {
	type MutationFunction,
	type QueryFunction,
	type QueryKey,
	type UseMutationOptions,
	type UseQueryOptions,
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import { type HttpResponse } from "@/lib/backend/api";
import { Sentry } from "@/lib/sentry";
import { type ExtractedError } from "./error";
import { extractionWrapper } from "./extract";

export function useExtractedMutation<
	TData = unknown,
	TError extends ExtractedError = ExtractedError,
	TVariables = unknown,
	TContext = unknown,
>(
	options: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn"> & {
		mutationFn: MutationFunction<HttpResponse<TData, any>, TVariables>;
	},
) {
	return useMutation<TData, TError, TVariables, TContext>({
		...options,
		mutationFn: (variables) => extractionWrapper<TData, TError>(options.mutationFn(variables)),
		onError: (error) => Sentry.captureException(error),
	});
}

export function useExtractedQuery<
	TQueryFnData = unknown,
	TError extends ExtractedError = ExtractedError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn"> & {
		queryFn: QueryFunction<HttpResponse<TQueryFnData, any>, TQueryKey>;
	},
) {
	return useQuery<TQueryFnData, TError, TData, TQueryKey>({
		...options,
		queryFn: (context) => extractionWrapper<TQueryFnData, TError>(options.queryFn(context)),
	});
}
