import { Sentry } from "@/lib/sentry";

export function getVersion() {
	const release = Sentry.getCurrentScope().getSession()?.release || __SENTRY_RELEASE__;

	if (release) {
		if (release.includes("@")) {
			return release.split("@")[1];
		}

		return release;
	}

	return "???";
}
