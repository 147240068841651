import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_publicFriendCheck = (params: {
	friendId: Parameters<
		typeof backendService.friendcheck.friendcheckControllerGetPublicFriendCheck
	>[0];
	friendHash: Parameters<
		typeof backendService.friendcheck.friendcheckControllerGetPublicFriendCheck
	>[1];
}) =>
	queryOptions({
		queryKey: ["friend-check", params] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.friendcheck.friendcheckControllerGetPublicFriendCheck(
					context.queryKey[1].friendId,
					context.queryKey[1].friendHash,
					{
						signal: context.signal,
					},
				),
			),
	});
