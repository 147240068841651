import { IonButtons, IonIcon, IonPage, IonRouterLink, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthStrategyAlert } from "@/components/Auth/Strategy/Alert";
import { AuthStrategyOAuth } from "@/components/Auth/Strategy/OAuth";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { type EmailVerificationCodeDto } from "@/lib/backend/api";
import { getErrorMessages } from "@/lib/backend/utils/error";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { envelopeIcon, sparklesIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { Sentry } from "@/lib/sentry";
import { useAuthFlowStore } from "@/lib/store/auth-flow";

export function AuthSignInMagic() {
	const posthog = usePostHog();
	const state = useAuthFlowStore();
	const router = useIonRouter();
	const params = Routes.AuthSignInMagic.useParams();
	const { t, i18n } = useTranslation();

	const mutationSendMagicLink = useExtractedMutation({
		mutationKey: ["auth", "magic", "send"],
		mutationFn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendMagicLink(variables, { format: "text" }),
	});

	useEffect(() => {
		if (params.error) {
			switch (params.error) {
				case "google_signin_cancelled":
					Toast.show({
						text: t("auth.error.google_cancelled"),
						duration: "long",
					});
					break;
				case "apple_signin_cancelled":
					Toast.show({
						text: t("auth.error.apple_cancelled"),
						duration: "long",
					});
					break;
				default:
					Toast.show({
						text: t("auth.error.other"),
						duration: "long",
					});
					break;
			}
		}
	}, [params.error]);

	const form = useForm({
		defaultValues: { email: state.email },
		validators: {
			onSubmit: z.object({ email: z.string().email(t("fields.email.errors.invalid")) }),
		},
		onSubmit: ({ value, formApi }) =>
			mutationSendMagicLink.mutateAsync(value, {
				onSuccess: (_data, variables) => {
					posthog.capture("User Send Magic Link", { success: true });
					useAuthFlowStore.setState(variables);
					router.push(Routes.AuthSignInMagicVerify(), "forward");
				},
				onError: (error) => {
					Sentry.captureException(error);
					posthog.capture("User Send Magic Link", { success: false });

					formApi.setFieldMeta("email", (current) => ({
						...current,
						errorMap: {
							onSubmit: getErrorText({
								language: i18n.language,
								errors: getErrorMessages(error).map((message) => {
									switch (message) {
										case "Email exists!":
											return t("fields.email.errors.used");
										default:
											return message;
									}
								}),
							}),
						},
					}));
				},
			}),
	});

	return (
		<IonPage>
			<AuthHeader>
				<IonButtons slot="primary">
					<Button
						data-attr="page.auth.sign-in.header.primary-button"
						routerLink={Routes.AuthSignUp()}
						routerDirection="forward"
						color="tertiary"
						size="small"
						className="part-native:text-brown-600"
					>
						<span>
							<Trans i18nKey="page.auth.sign-in.header.primary-button" />
						</span>
					</Button>
				</IonButtons>
			</AuthHeader>
			<AuthContent className="ion-pt-0">
				<TitleIcon icon={sparklesIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-in.title") },
						subtitle: { children: t("page.auth.sign-in.subtitle") },
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						children={(field) => (
							<FormInput
								data-attr="page.auth.sign-in.magic.index.email"
								field={field}
								type="email"
								label={t("fields.email.label")}
								autocomplete="email"
								inputMode="email"
								placeholder={t("fields.email.placeholder")}
							>
								<IonIcon slot="start" icon={envelopeIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton
								data-attr="page.auth.sign-in.magic.index.action.primary"
								disabled={!canSubmit}
								isSubmitting={isSubmitting}
							>
								{t("page.auth.sign-in.steps.magic.action.primary")}
							</AuthSubmitButton>
						)}
					/>

					<AuthStrategyAlert>
						<Trans
							i18nKey="page.auth.sign-in.steps.magic.alert"
							components={[
								<IonRouterLink
									key="0"
									data-attr="page.auth.sign-in.magic.index.strategy"
									routerLink={Routes.AuthSignInPassword()}
									routerDirection="forward"
									className="inline font-bold hover:underline focus:underline active:underline"
								/>,
							]}
						/>
					</AuthStrategyAlert>
				</form>

				<AuthStrategyOAuth />
			</AuthContent>
		</IonPage>
	);
}
