import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { VERIFICATION_CODE_RESEND_TIMEOUT_IN_SECONDS } from "@/lib/constants";
import { cx } from "@/lib/style/cva.config";
import { useCountdown } from "@/utils/hooks/useCountdown";

export const AuthResendButton = forwardRef<
	HTMLIonButtonElement,
	Omit<React.ComponentProps<typeof Button>, "onClick"> & {
		onClick: (
			event: React.MouseEvent<HTMLIonButtonElement> & {
				controllers: Record<"startCountdown" | "stopCountdown" | "resetCountdown", VoidFunction>;
			},
		) => void;
	}
>(function AuthResendButton(props, ref) {
	const { t } = useTranslation();
	const [count, controllers] = useCountdown({
		countStart: VERIFICATION_CODE_RESEND_TIMEOUT_IN_SECONDS,
	});

	useEffect(() => {
		controllers.startCountdown();

		return () => {
			controllers.stopCountdown();
		};
	}, []);

	return (
		<Button
			ref={ref}
			fill="clear"
			color="secondary"
			{...props}
			className={cx("font-bold ion-text-brown-400 part-native:leading-snug", props.className)}
			disabled={props.disabled || count !== 0}
			onClick={(event) => props?.onClick?.({ ...event, controllers })}
		>
			{t("auth.resend", { count })}
		</Button>
	);
});
