import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_reflection = () =>
	queryOptions({
		queryKey: ["reflection", "all"] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.reflection.reflectionControllerGetReflection({
					signal: context.signal,
				}),
			),
	});
