import { type ArrayOrSingle } from "ts-essentials";
import { z } from "zod";
import { getArrayOrSingle } from "@/utils/functions/getArrayOrSingle";

export class FetchError extends Error {
	constructor(
		public status: number,
		public statusText: string,
		public cause: any,
	) {
		super(statusText || status.toString(), { cause });
	}
}

export const ResponseError = z.object({
	error: z.string().optional(),
	statusCode: z.coerce.number(),
	message: z
		.custom<
			ArrayOrSingle<string>
		>((data) => typeof data === "string" || (Array.isArray(data) && data.every((item) => typeof item === "string")))
		.transform(getArrayOrSingle),
});
export type ResponseError = z.infer<typeof ResponseError>;

export const ResponseWithError = z.object({ error: ResponseError });
export type ResponseWithError = z.infer<typeof ResponseWithError>;

export type ExtractedError = FetchError | ResponseError | Error;

export function getErrorMessage<T = ExtractedError>(
	error: T,
	defaultValue: (error: T) => string = (error) => JSON.stringify(error),
): string {
	if (error instanceof FetchError || error instanceof Error) {
		if (!error.cause) {
			return error.message;
		}

		return `${error.cause.error ?? "Error"} (${error.cause.statusCode ?? -1}): ${error.cause.message ?? "???"}`;
	}

	if (typeof error === "string") {
		return error;
	}

	return defaultValue(error);
}

export function getErrorMessages(error: ExtractedError): string[] {
	if (error instanceof FetchError) {
		return getArrayOrSingle((JSON.parse(error.cause) as any).message);
	}

	if (error instanceof Error) {
		return getArrayOrSingle(error.message);
	}

	return error.message;
}
