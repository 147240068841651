import { cx } from "@/lib/style/cva.config";
import { LoadingThreeDots } from "../Loading/LoadingThreeDots";

export function ChatMessage({
	sender,
	parts,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	sender: "user" | "bot";
	parts?: {
		smallBubble?: React.ComponentPropsWithoutRef<"div">;
		text?: React.ComponentPropsWithoutRef<"div">;
	};
}) {
	return (
		<div
			{...props}
			data-attr="chat-bubble"
			className={cx(
				"relative w-fit max-w-[90%] rounded-2xl bg-brown-100 px-3 py-2 text-brown-700",
				sender === "user" && "me-3 ms-auto",
				sender === "bot" && "me-auto ms-3",
				props.className,
			)}
		>
			<div
				{...parts?.smallBubble}
				className={cx(
					"absolute bottom-0 size-3 rounded-full bg-brown-100",
					sender === "user" && "-right-3",
					sender === "bot" && "-left-3",
					parts?.smallBubble?.className,
				)}
			/>
			{!props.children && sender === "bot" && <LoadingThreeDots opacity={0.2} />}
			{typeof props.children === "string" ? (
				<p {...parts?.text} className={cx("font-medium text-brown-700", parts?.text?.className)}>
					{props.children}
				</p>
			) : (
				props.children
			)}
		</div>
	);
}
