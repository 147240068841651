import { IonCheckbox, IonModal, IonText } from "@ionic/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { queryOptions_coreValuesAnalysis } from "@/lib/query/functions/core-values/analysis";
import { queryOptions_coreValuesSummary } from "@/lib/query/functions/core-values/summary";
import { useMyData } from "@/lib/query/functions/user/data";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

const MotionButton = motion.create(Button);

export function ModalFriendCheckAnalysisView() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const contentRef = useRef<HTMLIonContentElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modalConfirmRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.friendCheckAnalysisView();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [myData, { mutation }] = useMyData();

	const [selectedValues, setSelectedValues] = useState<string[]>(
		myData.coreValues.savedFriendCheck,
	);

	const queryAllCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: modal.isOpen });
	const queryMyCoreValues = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "reflection" }),
		enabled: modal.isOpen,
	});

	const myCoreValues = queryMyCoreValues.data?.map((coreValue) => ({
		...coreValue,
		// Enrich with actual emojis and colors
		...queryAllCoreValues.data?.find((coreValueItem) => coreValueItem.name === coreValue.name),
	}));

	const querySummary = useQuery({
		...queryOptions_coreValuesSummary({ type: "friendcheck" }),
		enabled: modal.isOpen,
	});

	const queryAnalysis = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "friendcheck" }),
		enabled: modal.isOpen,
	});

	const coreValues = queryAnalysis.data
		?.filter((coreValue) => queryAllCoreValues.data?.some((value) => value.name === coreValue.name))
		.map((coreValue) => ({
			...coreValue,
			// Enrich with actual emojis and colors
			...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
		}));

	return (
		<>
			<IonModal
				ref={modalRef}
				isOpen={modal.isOpen}
				onWillPresent={() => {
					present("friendCheckAnalysisView");
					posthog.capture("Modal FriendCheck Analysis View Present");
				}}
				onWillDismiss={() => {
					dismiss("friendCheckAnalysisView");
					posthog.capture("Modal FriendCheck Analysis View Dismiss");
				}}
			>
				<Content ref={contentRef} fullscreen inModal className="part-scroll:gap-4">
					<CoreValueAvatarWithBackground coreValues={myCoreValues} />

					<motion.div
						initial={{ opacity: 0, display: "none" }}
						animate={{ opacity: 1, display: "flex" }}
						transition={{ duration: 0.5 }}
						className="flex flex-col gap-2 rounded-xl border border-brown-200 bg-brown-100 p-4"
					>
						<IonText className="text-base font-semibold text-brown-500">
							{t("modal.friend-check.analysis.generate.summary")}
						</IonText>
						<div
							data-mask-text
							className="block min-h-[4lh] text-pretty text-lg font-semibold text-brown-700"
						>
							{querySummary.data?.split(" ").map((word, index) => (
								<motion.span
									key={word + index}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.25, delay: index * 0.01 }}
									onAnimationComplete={() => contentRef.current?.scrollToBottom()}
								>
									{word}{" "}
								</motion.span>
							))}
						</div>
					</motion.div>

					{!!querySummary.data && (
						<>
							<motion.div
								initial="hidden"
								animate="visible"
								variants={{
									visible: { opacity: 1, display: "flex" },
									hidden: { opacity: 0, display: "none" },
								}}
								transition={{
									delay: querySummary.data.split(" ").length * 0.01 + 1,
									duration: 0.5,
								}}
								className="flex flex-col gap-3"
							>
								<div className="flex flex-col">
									<IonText className="text-xl font-bold text-brown-600">
										{t("modal.friend-check.analysis.generate.core-values.title")}
									</IonText>
									<IonText className="text-base font-normal text-brown-600">
										{t("modal.friend-check.analysis.generate.core-values.description")}
									</IonText>
								</div>

								<div className="flex flex-1 flex-col gap-3">
									{coreValues?.map((coreValue, index) => {
										const alreadyInList = queryMyCoreValues.data?.some(
											(coreValueAnalysis) => coreValueAnalysis.name === coreValue?.name,
										);

										const checked =
											alreadyInList || (!!coreValue?.id && selectedValues.includes(coreValue?.id));

										return (
											<motion.div
												key={index}
												className="flex flex-1"
												initial={{
													opacity: 0,
													scaleX: 0.75,
													y: "1rem",
													display: "none",
												}}
												animate={{
													opacity: 1,
													scaleX: 1,
													y: 0,
													display: "flex",
												}}
												transition={{
													duration: 0.25,
													delay:
														(querySummary.data?.split(" ").length ?? 0) * 0.01 + 1 + index * 0.25,
												}}
												onAnimationComplete={() => contentRef.current?.scrollToBottom()}
											>
												<IonCheckbox
													key={index}
													data-attr="modal.friend-check.analysis.view.core-value"
													className={cx(
														"flex-1 rounded-xl bg-brown-100 p-3 part-[label]:flex part-[label]:flex-1 part-[label]:items-center part-[label]:justify-between part-[label]:gap-2",
														alreadyInList && "part-container:opacity-50",
														!coreValue?.id && "hidden",
													)}
													checked={checked}
													disabled={alreadyInList || !coreValue?.id}
													value={coreValue?.id}
													onIonChange={(event) =>
														setSelectedValues((values) => {
															const value = String(event.detail.value);
															if (values.includes(value)) {
																return values.filter((v) => v !== value);
															} else {
																return [...values, value];
															}
														})
													}
												>
													<span className="flex flex-row gap-2 font-semibold text-brown-700">
														<IonText className="block min-w-5 text-center">
															{coreValue?.emoji || "❓"}
														</IonText>
														<IonText>{coreValue?.name || coreValue?.id || ""}</IonText>
													</span>

													{!coreValue.id && (
														<IonText className="text-end text-xs font-normal text-brown-400">
															{t(
																"modal.friend-check.analysis.generate.core-values.invalid-in-list",
															)}
														</IonText>
													)}

													{alreadyInList && (
														<IonText className="text-end text-xs font-normal text-brown-400">
															{t(
																"modal.friend-check.analysis.generate.core-values.already-in-list",
															)}
														</IonText>
													)}
												</IonCheckbox>
											</motion.div>
										);
									})}
								</div>
							</motion.div>

							<MotionButton
								data-attr="modal.friend-check.analysis.view.confirm.trigger"
								className="mt-auto text-lg font-bold"
								onClick={() => {
									if (selectedValues.length === 0) {
										modalRef.current?.dismiss();
									} else {
										modalConfirmRef.current?.present();
									}
								}}
								initial="hidden"
								animate="visible"
								variants={{
									visible: { opacity: 1, display: "flex" },
									hidden: { opacity: 0, display: "none" },
								}}
								transition={{
									delay: querySummary.data.split(" ").length * 0.01 + 2,
									duration: 0.5,
								}}
							>
								{t("modal.friend-check.analysis.generate.confirm.trigger", {
									count: selectedValues.length,
								})}
							</MotionButton>
						</>
					)}
				</Content>
			</IonModal>

			<IonModal
				ref={modalConfirmRef}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
			>
				<div className="ion-padding flex flex-col gap-8 pb-8 pt-12">
					<div className="flex flex-col gap-2">
						<IonText className="text-2xl font-bold text-brown-700">
							{t("modal.friend-check.analysis.generate.confirm.title")}
						</IonText>
						<IonText className="text-base font-normal text-brown-600">
							{t("modal.friend-check.analysis.generate.confirm.description", {
								count: selectedValues.length,
							})}
						</IonText>
					</div>
					<div className="flex flex-col gap-3">
						{coreValues
							?.filter((coreValue) => selectedValues.includes(String(coreValue.id)))
							.map((coreValue, index) => {
								return (
									<IonCheckbox
										key={index}
										className="rounded-xl bg-brown-100 p-3 opacity-100 part-[label]:flex part-[label]:flex-1 part-[label]:items-center part-[label]:justify-between part-[label]:gap-2"
										checked
										disabled
										value={coreValue?.id}
									>
										<span className="flex flex-row gap-2 font-semibold text-brown-700">
											<IonText className="block min-w-5 text-center">
												{coreValue?.emoji || "❓"}
											</IonText>
											<IonText>{coreValue?.name || coreValue?.id || ""}</IonText>
										</span>
									</IonCheckbox>
								);
							})}
					</div>
					<div className="flex flex-col gap-2 *:rounded-2xl *:text-lg *:font-bold">
						<Button
							data-attr="modal.friend-check.analysis.view.confirm.actions.confirm"
							onClick={() => {
								mutation.mutate(
									(draft) => void (draft.coreValues.savedFriendCheck = selectedValues),
								);
								posthog.capture("Modal FriendCheck Analysis View Finished");
								posthog.capture("Friend Check Analysis", {
									coreValues: coreValues
										?.filter((coreValue) => !!coreValue.id && selectedValues.includes(coreValue.id))
										.map((coreValue) => coreValue.name),
								});
								modalConfirmRef.current?.dismiss();
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.friend-check.analysis.generate.confirm.actions.confirm")}
						</Button>
						<Button
							data-attr="modal.friend-check.analysis.view.confirm.actions.dismiss"
							fill="clear"
							color="secondary"
							onClick={() => modalConfirmRef.current?.dismiss()}
						>
							{t("modal.friend-check.analysis.generate.confirm.actions.dismiss")}
						</Button>
					</div>
				</div>
			</IonModal>
		</>
	);
}
