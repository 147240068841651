import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_friendCheckSubmissions = () =>
	queryOptions({
		queryKey: ["friend-check", "submissions"] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.friendcheck.friendcheckControllerGetAllSubmissions({
					signal: context.signal,
				}),
			),
	});
