import { IonSkeletonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import Color from "color";
import { motion } from "motion/react";
import { type ChosenCoreValueItem } from "@/lib/backend/api";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";
import { DashboardCoreValuesWidgetCard as Card } from "./Card";

export function DashboardCoreValuesWidgetStack({
	isLoading = false,
	data,
	...props
}: React.ComponentPropsWithoutRef<"div"> & { isLoading?: boolean; data: ChosenCoreValueItem[] }) {
	const PreferredHaptics = usePreferredHaptics();
	const router = useIonRouter();
	const [currentIndex, setCurrentIndex] = useState(0);

	return (
		<div {...props} className={cx("flex flex-col items-center gap-2", props.className)}>
			<div className="relative isolate h-48 w-full">
				{isLoading
					? Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText
								key={index}
								animated
								className="absolute h-[9.5rem] border border-brown-200 bg-brown-100 ion-bg-brown-100 ion-rounded-3xl"
								style={{
									transform: `scaleX(${1 - 0.05 * index})`,
									zIndex: -10 * index,
									bottom: `${1 * index}rem`,
								}}
							/>
						))
					: data.map((chosenCoreValue, index, self) => (
							<Card
								key={chosenCoreValue.id}
								data-attr="dashboard.core-values-widget.card"
								coreValue={chosenCoreValue.coreValue}
								index={index}
								currentIndex={currentIndex}
								maxIndex={self.length}
								handleNext={() => setCurrentIndex((c) => ++c % self.length)}
								onClick={() => {
									router.push(Routes.CoreValuesDetails({ id: chosenCoreValue.id }));
								}}
							>
								<div
									className="flex gap-2"
									style={{
										// @ts-expect-error CSS variables are not typed
										"--background": Color(chosenCoreValue.coreValue.color)
											.alpha(0.25)
											.rgb()
											.toString(),
										"--foreground": Color(chosenCoreValue.coreValue.color)
											.darken(0.625)
											.rgb()
											.toString(),
									}}
								>
									<div
										className="left-[calc(50%-calc(var(--size)*0.5))] top-[calc(50%-calc(var(--size)*0.5))] grid size-[--size] origin-center select-none place-content-center rounded-full bg-[--background] will-change-transform [--size:calc(theme(size.12)*var(--size-scale,1))]"
										style={{
											// @ts-expect-error CSS Variables not typed
											"--size-scale": 0.75,
											x: 0,
											y: 0,
										}}
									>
										{chosenCoreValue.coreValue.emoji}
									</div>
									<strong className="line-clamp-4 flex-1 text-pretty text-xl font-semibold">
										<div className="inline-block h-9 rounded-full bg-[--background] px-3 py-1.5 text-lg text-[--foreground]">
											{chosenCoreValue.customName ?? chosenCoreValue.coreValue.name}
										</div>
									</strong>
								</div>
								<div data-mask-text className="font-regular line-clamp-3 font-normal">
									{chosenCoreValue.description ?? chosenCoreValue.reason}
								</div>
							</Card>
						))}
			</div>

			{isLoading ? (
				<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
					{Array.from({ length: 3 }).map((_, index) => (
						<button key={index} className={index === 0 ? "bg-brown-500" : "bg-brown-300"} />
					))}
				</div>
			) : (
				data.length > 1 && (
					<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
						{data.map((coreValue, index) => (
							<button
								key={coreValue.id}
								className="bg-brown-300"
								onClick={() => {
									PreferredHaptics.impact();
									setCurrentIndex(index);
								}}
							/>
						))}
						<motion.div
							className="pointer-events-none absolute left-0 z-10 bg-brown-500"
							initial={{ x: "0rem" }}
							animate={{ x: `${currentIndex * 1.125}rem` }}
							transition={{ type: "spring", duration: 0.3 }}
						/>
					</div>
				)
			)}
		</div>
	);
}
