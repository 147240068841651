import { IonBackButton, IonButtons, IonHeader } from "@ionic/react";
import { Toolbar } from "@/components/Global/Toolbar";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { cx } from "@/lib/style/cva.config";

export function AuthHeader({
	parts,
	...props
}: React.ComponentProps<typeof IonHeader> & {
	parts?: {
		// @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382
		backButton?: React.ComponentProps<typeof IonBackButton> & any;
		toolbar?: React.ComponentProps<typeof Toolbar>;
	};
}) {
	const PreferredHaptics = usePreferredHaptics();

	return (
		<IonHeader {...props} className={cx("ion-no-border text-brown-600", props.className)}>
			<Toolbar
				{...parts?.toolbar}
				className={cx(
					"plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]",
					parts?.toolbar,
				)}
			>
				{!!parts?.backButton && (
					<IonButtons
						className="text-sm"
						slot="secondary"
						onClick={() => PreferredHaptics.impact()}
					>
						{/* @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382 */}
						<IonBackButton
							data-attr="auth.header.back-button"
							data-touch-target
							color="light"
							icon={arrowLeftIcon}
							text=""
							{...(parts.backButton as any)}
							className={cx(
								"ion-bg-a-brown-300 ion-bg-f-brown-300 part-icon:m-0 part-icon:size-5 part-native:min-h-10 part-native:min-w-10 part-native:rounded-full part-native:bg-brown-300 part-native:p-2.5 part-native:text-xs part-native:text-brown-700",
								parts.backButton.className,
							)}
						/>
					</IonButtons>
				)}

				{props.children}
			</Toolbar>
		</IonHeader>
	);
}
