import { IonHeader, IonIcon, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalPushNotificationsDenied() {
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.appPushNotificationsDenied();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("appPushNotificationsDenied")}
			onWillDismiss={() => dismiss("appPushNotificationsDenied")}
			className=""
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<Button
						slot="secondary"
						className="min-h-0 ion-bg-a-brown-300 ion-bg-f-brown-300 ion-bg-transparent ion-border-brown-300 ion-text-a-brown-600 ion-text-brown-600 ion-text-f-brown-600 ion-text-h-brown-600 ion-rounded-full ion-p-2 ion-border"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content inModal>
				<div className="flex h-full flex-col gap-2 text-brown-700">
					<IonText className="text-4xl font-bold">
						{t("settings.notifications.status.denied.title")}
					</IonText>

					<IonText className="text-balance text-base">
						{t("settings.notifications.status.denied.description")}
					</IonText>

					<Button
						expand="block"
						className="part-native:text-md m-0 mt-auto h-12 ion-rounded-2xl part-native:font-bold"
					>
						{t("settings.notifications.status.denied.actions.open-settings")}
					</Button>
					<Button
						expand="block"
						slot="secondary"
						className="part-native:text-md m-0 h-12 ion-bg-a-brown-300 ion-bg-f-brown-300 ion-bg-h-transparent ion-bg-transparent ion-border-brown-300 ion-text-a-brown-600 ion-text-brown-600 ion-text-f-brown-600 ion-text-h-brown-600 ion-rounded-2xl part-native:font-bold"
					>
						{t("settings.notifications.status.denied.actions.dismiss")}
					</Button>
				</div>
			</Content>
		</IonModal>
	);
}
