import { IonPage, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { TitleIcon } from "@/components/TitleIcon";
import { env } from "@/env";
import { backendService } from "@/lib/backend";
import { getErrorMessages } from "@/lib/backend/utils/error";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { keyIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { Sentry } from "@/lib/sentry";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { logger } from "@/logger";

export function AuthForgotPasswordReset() {
	const posthog = usePostHog();
	const { t, i18n } = useTranslation();
	const state = useAuthFlowStore();
	const authenticate = useSelectorAuthStore.use.authenticate();
	const router = useIonRouter();
	const searchParams = new URLSearchParams(router.routeInfo.search);
	const queryParamEmail = searchParams.get("email");
	const queryParamVerificationCode =
		searchParams.get("code") || searchParams.get("verificationCode");

	const email = queryParamEmail || state.email;
	const verificationCode = queryParamVerificationCode || state.verificationCode;

	const mutationResetPassword = useExtractedMutation({
		mutationKey: ["auth", "password-reset", "reset"],
		mutationFn: backendService.auth.authControllerResetPassword,
	});

	const form = useForm({
		defaultValues: {
			email,
			verificationCode,
			password: state.password,
		},
		validators: {
			onChange: z.object({
				email: z.string(),
				verificationCode: z.string(),
				password: z
					.string()
					.min(
						env.VITE_PASSWORD_MIN_LENGTH,
						t("fields.password.errors.min", {
							number: env.VITE_PASSWORD_MIN_LENGTH,
						}),
					)
					.refine(
						(value) => {
							let requirementMet = 0;

							// Uppercase
							if (/[A-Z]/g.test(value)) {
								requirementMet++;
							}

							// Lowercase
							if (/[a-z]/g.test(value)) {
								requirementMet++;
							}

							// Number
							if (/[0-9]/g.test(value)) {
								requirementMet++;
							}

							// Symbol
							if (/[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|\\":<>?._]/.test(value)) {
								requirementMet++;
							}

							return requirementMet >= env.VITE_PASSWORD_REQUIREMENTS;
						},
						{
							message: t("fields.password.errors.requirement", {
								requirement: env.VITE_PASSWORD_REQUIREMENTS,
							}),
						},
					),
			}),
		},
		onSubmit: ({ value, formApi }) =>
			mutationResetPassword.mutateAsync(value, {
				onSuccess: (data) => {
					posthog.capture("User Forgot Password Reset", { success: true });
					Toast.show({
						duration: "long",
						text: t("page.auth.forgot-password.message.success"),
					});
					authenticate(data);
					state.reset();
				},
				onError: (error) => {
					Sentry.captureException(error);
					posthog.capture("User Forgot Password Reset", { success: false });
					logger.getLogger("Auth").error("User Forgot Password Reset", { error });

					formApi.setFieldMeta("password", (current) => ({
						...current,
						errorMap: {
							onSubmit: getErrorText({
								language: i18n.language,
								errors: getErrorMessages(error).map((message) => {
									switch (message) {
										case "Unauthorized":
											return t("fields.verificationCode.errors.incorrect");
										default:
											return message;
									}
								}),
							}),
						},
					}));
				},
			}),
	});

	if (!verificationCode) {
		if (!email) {
			return <Redirect to={Routes.AuthForgotPassword()} />;
		}

		return <Redirect to={Routes.AuthForgotPasswordVerify()} />;
	}

	return (
		<IonPage>
			<AuthHeader parts={{ backButton: { defaultHref: Routes.AuthForgotPasswordVerify() } }} />
			<AuthContent>
				<TitleIcon icon={keyIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.forgot-password.steps.password.title") },
						subtitle: {
							children: t("page.auth.forgot-password.steps.password.subtitle", {
								number: env.VITE_PASSWORD_MIN_LENGTH,
								requirement: env.VITE_PASSWORD_REQUIREMENTS,
							}),
						},
					}}
				>
					<ul className="ml-6 list-disc">
						<li>{t("fields.password.requirements.capital")}</li>
						<li>{t("fields.password.requirements.lowercase")}</li>
						<li>{t("fields.password.requirements.digit")}</li>
						<li>{t("fields.password.requirements.symbol")}</li>
					</ul>
				</AuthPageHeaderTitle>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateField("password", "change");
						form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						children={(field) => (
							<input
								type="email"
								name={field.name}
								autoComplete="email"
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="verificationCode"
						children={(field) => (
							<input
								type="text"
								name={field.name}
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="password"
						children={(field) => (
							<FormInput
								data-attr="page.auth.forgot-password.reset.password"
								field={field}
								type="password"
								autofocusOnIonViewDidEnter
								label={t("fields.password.label")}
								autocomplete="new-password"
								placeholder={t("fields.password.placeholder")}
							/>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton
								data-attr="page.auth.forgot-password.reset.action.primary"
								disabled={!canSubmit}
								isSubmitting={isSubmitting}
							>
								{t("page.auth.forgot-password.steps.password.action.primary")}
							</AuthSubmitButton>
						)}
					/>
				</form>
			</AuthContent>
		</IonPage>
	);
}
