import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonPage,
	IonTitle,
	IonToggle,
	useIonViewWillEnter,
} from "@ionic/react";
import { Capacitor, type PermissionState } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function SettingsNotifications() {
	const { t } = useTranslation();
	const preferredHaptics = usePreferredHaptics();

	const { data: settings } = useExtractedQuery({
		queryKey: ["user", "me", "settings"] as const,
		queryFn: (context) =>
			backendService.user.usersControllerGetMySettings({ signal: context.signal }),
	});

	const presentModal = useSelectorModalStore.use.present();

	const [notificationStatus, setNotificationStatus] = useState<PermissionState>();
	const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

	const api = {
		updateNotificationSettings: async (enabled: boolean) => {
			await backendService.user.usersControllerUpdateSettings({
				notificationsEnabled: enabled,
			});
			setIsNotificationEnabled(enabled);

			Toast.show({
				text: enabled
					? t("settings.notifications.status.enabled")
					: t("settings.notifications.status.disabled"),
			});
		},
	};

	useIonViewWillEnter(() => {
		checkNotificationStatus();
	});

	const checkNotificationStatus = async () => {
		if (Capacitor.getPlatform() === "web") {
			Toast.show({
				text: t("settings.notifications.status.web.error"),
			});
			return;
		}

		const permissionStatus = await PushNotifications.requestPermissions();
		setNotificationStatus(permissionStatus.receive);

		const dbEnabled = !!settings?.notificationsEnabled;
		setIsNotificationEnabled(dbEnabled);
		if (permissionStatus.receive === "denied") {
			presentModal("appPushNotificationsDenied");
			return;
		}

		if (permissionStatus.receive === "granted" && dbEnabled) {
			await registerDevice();
		}
	};

	const registerDevice = async () => {
		await PushNotifications.register();
	};

	const handleToggleChange = async (checked: boolean) => {
		await api.updateNotificationSettings(checked);
	};

	return (
		<IonPage>
			<IonHeader translucent>
				<Toolbar className="plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary" onClick={() => preferredHaptics.impact()}>
						{/* @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382 */}
						<IonBackButton
							data-touch-target
							data-attr="page.settings.notifications.back"
							defaultHref={Routes.Settings()}
							color="light"
							className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
					<IonTitle>{t("settings.notifications.name")}</IonTitle>
				</Toolbar>
			</IonHeader>

			<Content fullscreen>
				<IonHeader collapse="condense">
					<Toolbar className="!ion-px-0">
						<IonTitle size="large" className="px-0 text-2xl text-brown-700">
							{t("settings.notifications.name")}
						</IonTitle>
					</Toolbar>
				</IonHeader>

				<div className="notification-settings">
					<IonToggle
						data-attr="page.settings.notifications.toggle"
						disabled={Capacitor.getPlatform() === "web" || notificationStatus === "denied"}
						checked={isNotificationEnabled && notificationStatus === "granted"}
						onIonChange={(e) => handleToggleChange(e.detail.checked)}
					>
						{isNotificationEnabled && notificationStatus === "granted"
							? t("settings.notifications.status.enabled")
							: t("settings.notifications.status.disabled")}
					</IonToggle>
				</div>
			</Content>
		</IonPage>
	);
}
