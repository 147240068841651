import { useMemo } from "react";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryOptions_myProgression = () =>
	queryOptions({
		queryKey: ["user", "me", "progression"] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.user.usersControllerGetMyProgressionStatus({ signal: context.signal }),
			),
		initialData: {
			core_values: "NOT_STARTED",
			friend_check: "NOT_STARTED",
			reflection: "NOT_STARTED",
			chosen_core_values: "NOT_STARTED",
		} satisfies Awaited<
			ReturnType<typeof backendService.user.usersControllerGetMyProgressionStatus>
		>["data"],
	});

export function useMyProgression(options?: Partial<ReturnType<typeof queryOptions_myProgression>>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({
		...queryOptions_myProgression(),
		enabled: isAuthenticated,
		...options,
	});
	const myProgression = query.data || queryOptions_myProgression().initialData;

	return useMemo(
		() => [myProgression, query] as const,
		[myProgression, query.status, query.dataUpdatedAt, query.data],
	);
}
