import { useEffect } from "react";
import { getFullName } from "@/lib/getFullName";
import { useMe } from "@/lib/query/functions/user/me";
import { Sentry } from "@/lib/sentry";

export function SentryProvider(props: React.ComponentProps<typeof Sentry.ErrorBoundary>) {
	const [me] = useMe();

	useEffect(() => {
		if (!me) {
			return;
		}

		Sentry.setUser({
			id: me.id,
			email: me.email,
			username: getFullName(me),
		});
	}, [me]);

	return <Sentry.ErrorBoundary {...props} />;
}
