import {
	IonBackButton,
	IonButtons,
	IonCheckbox,
	IonHeader,
	IonPage,
	IonRadio,
	IonRadioGroup,
	useIonRouter,
} from "@ionic/react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { useShallow } from "zustand/shallow";
import EmptyAvatar from "@/assets/empty_avatar.svg";
import SplashIcon from "@/assets/splash_icon.svg";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { Toast } from "@/lib/capacitor/Toast";
import { PAGE_TERMS_AND_CONDITIONS_URL } from "@/lib/constants";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { queryOptions_publicFriendCheck } from "@/lib/query/functions/friend-check/public";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { authSelectors, useAuthStore } from "@/lib/store/auth";
import { useFriendCheckStore } from "@/lib/store/friend-check";
import { cx } from "@/lib/style/cva.config";

export function SharedFriendCheckInformation() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const state = useFriendCheckStore();
	const searchParams = Routes.SharedFriendCheck.useParams();
	const params = useFriendCheckStore(
		useShallow((state) => ({
			id: state.friendId,
			hash: state.friendHash,
		})),
	);
	const hasSearchParams = !!searchParams.id && !!searchParams.hash;
	const hasParams = !!params.id && !!params.hash;

	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));
	const [me] = useMe({ enabled: isAuthenticated });

	useEffect(() => {
		if (!hasParams && hasSearchParams) {
			useFriendCheckStore.setState({ friendId: searchParams.id, friendHash: searchParams.hash });
		}
	}, [hasSearchParams, hasParams]);

	const query = useQuery({
		...queryOptions_publicFriendCheck({ friendId: params.id, friendHash: params.hash }),
		enabled: hasParams,
		retry: false,
	});

	useEffect(() => {
		if (isAuthenticated) {
			if (!!me?.id && !!query.data?.id && me?.id === query.data?.id) {
				Toast.show({ text: t("page.shared-friend-check.self-view") });
				posthog.capture("Page Share Friend Check Self View");
				router.push(Routes.Dashboard(), "none", "replace");
			}

			return;
		}

		// Identify with a generic name
		posthog.setPersonProperties(
			{ name: "Friend Check Person" },
			{ friend_check_referral: params.id },
		);
	}, [isAuthenticated, query.data, me]);

	useEffect(() => {
		posthog.register_for_session({ friendId: params.id });
	}, [params.id]);

	const friendCheck = query.data;

	const form = useForm<{
		name: string;
		relation: string;
		acceptsTerms: boolean;
		email: string;
		acceptsEmail: boolean;
	}>({
		defaultValues: {
			name: state.name || me?.name || "",
			relation: state.relation || "",
			acceptsTerms: state.acceptsTerms || false,
			email: state.email || me?.email || "",
			acceptsEmail: state.acceptsEmail || false,
		},
		validators: {
			onSubmit: z.object({
				name: z
					.string()
					.min(1, t("fields.name.errors.min", { count: 1 }))
					.regex(/^^[^@]+$/, t("fields.name.errors.no-email")),
				relation: z.enum(["colleague", "family", "friend", "other"], {
					message: t("fields.options.errors.required", { count: 1 }),
				}),
				acceptsTerms: z.coerce
					.boolean()
					.refine(
						(value) => value === true,
						t("page.shared-friend-check.fields.acceptTerms.errors.required"),
					),
				email: z.string(),
				acceptsEmail: z.coerce.boolean(),
			}),
		},
		onSubmit: ({ value }) => {
			useFriendCheckStore.setState((draft) => {
				draft.name = value.name;
				draft.relation = value.relation;
				draft.acceptsTerms = value.acceptsTerms;
				draft.email = value.email;
				draft.acceptsEmail = value.acceptsEmail;
			});

			posthog.capture("Friend Check Started", { friendId: state.friendId });

			if (!isAuthenticated) {
				posthog.setPersonProperties({ name: `Friend Check Person: ${value.name}` });
			}

			posthog.register_for_session({
				friendId: state.friendId,
				relation: value.relation,
				acceptsTerms: value.acceptsTerms,
				email: value.email,
				acceptsEmail: value.acceptsEmail,
			});

			router.push(Routes.SharedFriendCheckQuestions());
		},
	});

	if ((!query.isLoading && !query.data) || (!hasParams && !hasSearchParams)) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
						<IonButtons
							slot="start"
							onClick={() => PreferredHaptics.impact()}
							className="hidden plt-native:block"
						>
							{/* @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382 */}
							<IonBackButton
								data-attr="page.share.friend-check.back"
								data-touch-target
								defaultHref={Routes.App()}
								color="light"
								className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
								icon={arrowLeftIcon}
								text=""
							/>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.shared-friend-check.not-found.title")}
						description={t("page.shared-friend-check.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<div
						slot="start"
						className="flex min-w-0 items-center justify-start gap-3 plt-native:hidden"
					>
						<img
							src={SplashIcon}
							className="-mx-2 -mb-4 -mt-[1.125rem] size-12 min-h-12 min-w-12 select-none drag-none"
						/>
						<span className="truncate text-start text-base font-semibold text-brown-700">
							{t("seo.title")}
						</span>
					</div>
					<IonButtons
						slot="start"
						onClick={() => PreferredHaptics.impact()}
						className="hidden plt-native:block"
					>
						{/* @ts-expect-error Ionic x React types are not up-to-date @url https://github.com/ionic-team/ionic-framework/issues/29991#issuecomment-2543156382 */}
						<IonBackButton
							data-attr="page.share.friend-check.back"
							data-touch-target
							defaultHref={Routes.App()}
							color="light"
							className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>

			<Content>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
					className="flex flex-1 flex-col gap-4"
				>
					<div className="my-6 flex flex-col items-center gap-3">
						<img
							src={friendCheck?.avatarURL || EmptyAvatar}
							referrerPolicy="no-referrer"
							className="ph-no-capture size-16 select-none rounded-full object-cover drag-none"
						/>
						<div className="flex flex-col items-center gap-2">
							<h1
								data-mask-text
								className="text-pretty text-center text-2xl font-bold text-brown-700"
							>
								{t(
									`page.shared-friend-check.information.title.${friendCheck?.name ? "name" : "none"}`,
									{
										name: friendCheck?.name,
									},
								)}
							</h1>
							<span
								data-mask-text
								className="text-balance text-center text-base font-medium text-brown-600"
							>
								{t(
									`page.shared-friend-check.information.description.${friendCheck?.name ? "name" : "none"}`,
									{ name: friendCheck?.name },
								)}
							</span>
						</div>
					</div>

					<form.Field
						name="name"
						children={(field) => (
							<div className="flex flex-col gap-4 rounded-2xl bg-white p-3">
								<FormInput
									data-attr="page.share.friend-check.information.name"
									field={field}
									autocomplete="given-name"
									disabled={field.state.meta.isValidating || field.form.state.isSubmitting}
									label={t("page.shared-friend-check.fields.name.label")}
									placeholder={t("fields.givenName.placeholder")}
									className="[&_.input-wrapper]:!gap-4 [&_.label-text-wrapper]:!mt-0 [&_.label-text-wrapper]:!transform-none [&_.label-text]:after:text-brown-400 [&_.label-text]:after:content-['_*'] [&_.native-wrapper]:!border-brown-200"
								/>
							</div>
						)}
					/>

					<form.Field
						name="relation"
						children={(field) => (
							<div className="flex flex-col gap-4 rounded-2xl bg-white p-3">
								<label
									data-mask-text
									htmlFor={field.name}
									className="text-base font-semibold ion-text-brown-600 after:text-brown-400 after:content-['_*']"
								>
									{t(
										`page.shared-friend-check.fields.relation.label.${friendCheck?.name ? "name" : "none"}`,
										{
											name: friendCheck?.name,
										},
									)}
								</label>
								<IonRadioGroup
									data-attr="page.share.friend-check.information.relation.radio-group"
									name={field.name}
									value={field.state.value}
									onIonChange={(event) => field.handleChange(String(event.detail.value))}
									onBlur={field.handleBlur}
									className={cx(
										"flex flex-col gap-2",
										field.state.meta.isTouched && "ion-touched",
										!!field.state.meta.errors &&
											field.state.meta.errors.length > 0 &&
											"ion-invalid",
									)}
								>
									{(["colleague", "family", "friend", "other"] as const).map((option, index) => (
										<IonRadio
											key={index}
											data-attr="page.share.friend-check.information.relation.radio"
											value={option}
											disabled={field.state.meta.isValidating || field.form.state.isSubmitting}
											className="rounded-2xl border-2 border-brown-200 bg-brown-100 p-4 text-base font-medium text-brown-600 transition-all ion-checked:border-orange-400 ion-checked:font-bold ion-checked:text-orange-500 part-container:size-6 part-container:border-brown-200 part-container:bg-white part-container:transition-all part-mark:-translate-y-px part-mark:rotate-45"
										>
											{t(`page.shared-friend-check.fields.relation.options.${option}`)}
										</IonRadio>
									))}
									{!!field.state.meta.errors && !!field.state.meta.errors.length && (
										<span className="text-xs text-danger-500">
											{getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
										</span>
									)}
								</IonRadioGroup>
							</div>
						)}
					/>

					<form.Field
						name="acceptsTerms"
						children={(field) => (
							<div
								className={cx(
									"isolate flex flex-col gap-2 rounded-2xl border-2 border-brown-300 p-3 contain-paint",
									field.state.meta.isTouched && "ion-touched",
									!!field.state.meta.errors && field.state.meta.errors.length > 0 && "ion-invalid",
								)}
							>
								<IonCheckbox
									data-attr="page.share.friend-check.information.accept-terms.checkbox"
									value={field.state.value}
									labelPlacement="end"
									justify="start"
									name={field.name}
									checked={field.state.value}
									onIonChange={(event) => field.handleChange(event.detail.checked)}
									onIonBlur={field.handleBlur}
									disabled={field.state.meta.isValidating || field.form.state.isSubmitting}
									className="-m-3 -mb-8 p-3 pb-8 text-base font-semibold text-brown-700 ion-border-brown-200 ion-checkbox-bg-transparent part-container:border-brown-300 part-container:bg-white [&_input]:hidden"
								>
									{t("page.shared-friend-check.fields.acceptTerms.label")}
								</IonCheckbox>
								<Trans
									i18nKey="page.shared-friend-check.fields.acceptTerms.description"
									components={[
										<a
											key="0"
											data-attr="page.share.friend-check.information.accept-terms.link"
											target="_blank"
											href={PAGE_TERMS_AND_CONDITIONS_URL}
											className="z-10 -mt-2 ms-9 w-fit text-sm text-brown-500 underline"
											onClick={(event) => event.stopPropagation()}
											rel="noreferrer"
										/>,
									]}
								/>
								{!!field.state.meta.errors && !!field.state.meta.errors.length && (
									<span className="ms-9 text-xs text-danger-500">
										{getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
									</span>
								)}
							</div>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<Button
								data-attr="page.share.friend-check.information.next"
								type="submit"
								className="mt-auto min-h-14 w-full rounded-lg text-lg font-bold"
								disabled={!canSubmit || isSubmitting}
							>
								{t("page.shared-friend-check.information.next")}
							</Button>
						)}
					/>
				</form>
			</Content>
		</IonPage>
	);
}
