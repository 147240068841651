import { IonIcon, IonProgressBar, IonRouterLink, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { checkIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";

export function CoreValueProgress({
	...props
}: React.ComponentProps<typeof IonRouterLink> & { withAction?: boolean }) {
	const { t } = useTranslation();

	const queryCoreValuesChosen = useQuery({
		...queryOptions_coreValuesChosen(),
	});

	const current =
		queryCoreValuesChosen.data?.filter((coreValue) => coreValue.customName).length ?? 0;
	const target = queryCoreValuesChosen.data?.length ?? 1;

	return (
		<IonRouterLink
			data-attr="core-value.progress"
			data-current-value={current}
			data-target-value={target}
			routerLink={Routes.CoreValues()}
			routerDirection="none"
			{...props}
			className={cx(
				"flex flex-col rounded-2xl border border-brown-200 bg-brown-100 p-3 text-start",
				props.className,
			)}
		>
			<div className="flex flex-col">
				<IonText className="font-bold leading-5 text-brown-700">
					{t("core-values.personalize.title")}
				</IonText>

				<IonText className="text-sm leading-5 text-brown-700">
					{t("core-values.personalize.count", {
						current,
						target,
					})}
				</IonText>
			</div>

			<div className="relative isolate flex h-5 w-full items-center">
				<IonProgressBar value={current / target} className="h-2 ion-bg-brown-200" />
				<div
					aria-checked={current === target}
					className="absolute inset-y-0 right-0 z-10 grid size-5 place-content-center rounded-full bg-brown-200 text-transparent aria-checked:bg-orange aria-checked:text-white"
				>
					<IonIcon icon={checkIcon} className="size-4" />
				</div>
			</div>
		</IonRouterLink>
	);
}
