import { IonCheckbox } from "@ionic/react";

export function MultipleChoiceAnswer({ selected }: { selected: string[] }) {
	return (
		<div className="flex flex-col gap-2">
			{selected.map((choice, index) => (
				<IonCheckbox
					key={index}
					justify="start"
					labelPlacement="end"
					checked={true}
					disabled
					className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700 opacity-100 part-[label]:overflow-auto part-[label]:whitespace-normal"
				>
					{choice}
				</IonCheckbox>
			))}
		</div>
	);
}
