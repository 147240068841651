import { IonPage, useIonRouter } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthResendButton } from "@/components/Auth/ResendButton";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import {
	type EmailVerificationCodeDto,
	type VerifySignupVerificationCodeDto,
} from "@/lib/backend/api";
import { getErrorMessages } from "@/lib/backend/utils/error";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { VERIFICATION_CODE_LENGTH } from "@/lib/constants";
import { envelopeOpenIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { Sentry } from "@/lib/sentry";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { logger } from "@/logger";

export function AuthForgotPasswordVerify() {
	const posthog = usePostHog();
	const { t, i18n } = useTranslation();
	const state = useAuthFlowStore();
	const router = useIonRouter();

	const mutationSendCode = useExtractedMutation({
		mutationKey: ["auth", "password-reset", "send"],
		mutationFn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendPasswordResetVerificationCode(variables, {
				format: "text",
			}),
	});

	const mutationVerifyCode = useExtractedMutation({
		mutationKey: ["auth", "password-reset", "verify"],
		mutationFn: (variables: VerifySignupVerificationCodeDto) =>
			backendService.auth.authControllerVerifyPasswordResetVerificationCode(variables, {
				format: "text",
			}),
	});

	const form = useForm({
		defaultValues: { email: state.email, verificationCode: state.verificationCode },
		validators: {
			onSubmit: z.object({
				email: z.string(),
				verificationCode: z
					.string()
					.min(
						VERIFICATION_CODE_LENGTH,
						t("fields.verificationCode.errors.min", {
							number: VERIFICATION_CODE_LENGTH,
						}),
					)
					.refine((value) => /^[0-9]+$/.test(value), {
						message: t("fields.verificationCode.errors.number"),
					}),
			}),
		},
		onSubmit: ({ value, formApi }) =>
			mutationVerifyCode.mutateAsync(value, {
				onSuccess: (data, values) => {
					const result = z.coerce.boolean().safeParse(data);

					posthog.capture("User Forgot Password Verify", { success: result.success });
					if (result.success) {
						useAuthFlowStore.setState(values);
						router.push(Routes.AuthForgotPasswordReset(), "forward");
					} else {
						formApi.setFieldMeta("verificationCode", (current) => ({
							...current,
							errorMap: {
								onSubmit: t("fields.verificationCode.errors.incorrect"),
							},
						}));
					}
				},
				onError: (error) => {
					Sentry.captureException(error);
					posthog.capture("User Forgot Password Verify", { success: false });
					logger.getLogger("Auth").error("User Forgot Password Verify", { error });

					formApi.setFieldMeta("verificationCode", (current) => ({
						...current,
						errorMap: {
							onSubmit: getErrorText({
								language: i18n.language,
								errors: getErrorMessages(error).map((message) => {
									switch (message) {
										case "Unauthorized":
											return t("fields.verificationCode.errors.incorrect");
										default:
											return message;
									}
								}),
							}),
						},
					}));
				},
			}),
	});

	if (!state.email) {
		return <Redirect to={Routes.AuthForgotPassword()} />;
	}

	return (
		<IonPage>
			<AuthHeader parts={{ backButton: { defaultHref: Routes.AuthForgotPassword() } }} />
			<AuthContent>
				<TitleIcon icon={envelopeOpenIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.forgot-password.steps.verify.title") },
						subtitle: {
							children: (
								<span data-mask-text className="contents">
									<Trans
										i18nKey="page.auth.forgot-password.steps.verify.subtitle"
										values={{ email: state.email }}
									/>
								</span>
							),
						},
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						children={(field) => (
							<input
								type="email"
								name={field.name}
								autoComplete="email"
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="verificationCode"
						children={(field) => (
							<FormInput
								data-attr="page.auth.forgot-password.verify.verificationCode"
								field={field}
								type="text"
								autofocusOnIonViewDidEnter
								inputMode="numeric"
								label={t("fields.verificationCode.label")}
								placeholder={t("fields.verificationCode.placeholder")}
								minlength={VERIFICATION_CODE_LENGTH}
								maxlength={VERIFICATION_CODE_LENGTH}
							/>
						)}
					/>

					<div className="flex flex-col gap-2">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<AuthSubmitButton
									data-attr="page.auth.forgot-password.verify.action.primary"
									disabled={!canSubmit}
									isSubmitting={isSubmitting}
								>
									{t("page.auth.forgot-password.steps.verify.action.primary")}
								</AuthSubmitButton>
							)}
						/>

						<AuthResendButton
							data-attr="page.auth.forgot-password.verify.action.resend"
							disabled={mutationSendCode.isPending}
							onClick={({ controllers }) =>
								mutationSendCode.mutate(
									{ email: state.email },
									{
										onSettled: () => {
											controllers.resetCountdown();
											controllers.startCountdown();
										},
									},
								)
							}
						/>
					</div>
				</form>
			</AuthContent>
		</IonPage>
	);
}
