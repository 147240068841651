import { queryOptions } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";

export const queryOptions_coreValuesSummary = (
	params: Parameters<typeof backendService.corevalues.coreValuesControllerGetReflectionSummary>[0],
) =>
	queryOptions({
		queryKey: ["core-values", "summary", params] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetReflectionSummary(params, {
					signal: context.signal,
					format: "text",
				}),
			),
	});
