import {
	IonButton,
	IonButtons,
	IonFooter,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { ChatProvider, useChat, useChatStore } from "@/components/Chat/Chat";
import { ChatContent } from "@/components/Chat/ChatContent";
import { ChatFooter } from "@/components/Chat/ChatFooter";
import { ChatInput } from "@/components/Chat/ChatInput";
import { ChatMessage } from "@/components/Chat/ChatMessage";
import { CoreValueCard } from "@/components/CoreValue/CoreValueCard";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type RefineCoreValueDto } from "@/lib/backend/api";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMutationStream } from "@/lib/query/hooks/useMutationStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function CoreValuesChat() {
	const params = Routes.CoreValuesChat.useParams();
	const presentModal = useSelectorModalStore.use.present();
	const { t } = useTranslation();

	const chatStore = useChat();

	const chat = useChatStore(chatStore);

	const queryChosenCoreValue = useQuery({
		queryKey: ["chosen-core-value", params.id!] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetChosenCoreValue(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: !!params.id,
		retry: false,
	});

	const id = params.id!;

	const coreValue = queryChosenCoreValue.data;

	const [streamedResponse, mutationStream] = useMutationStream({
		mutationKey: ["chosen-core-value", "message"],
		mutationFn: (params, variables: RefineCoreValueDto) =>
			backendService.corevalues.coreValuesControllerRefineCoreValue(variables, params),
		onSettled: async () => {
			await queryChosenCoreValue.refetch();
			chat.setResponding(false);
		},
	});

	useEffect(() => {
		if (coreValue?.messages.length == 0 && !mutationStream.isPending) {
			mutationStream.mutate({ question: "", chosenCoreValueId: id });
			chat.setResponding(true);
		}
	}, [coreValue?.messages]);

	if (streamedResponse !== chat.streamAssistantMessage) {
		chat.setStreamAssistantMessage(streamedResponse);
	}

	const unansweredProposal = coreValue?.messages.find(
		(msg) => msg.messageRole === "PROPOSAL" && msg.refineProposal.answered === false,
	);

	if (!queryChosenCoreValue.isLoading && !queryChosenCoreValue.data && params.id) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
						<IonButtons slot="secondary">
							<IonButton
								data-attr="page.core-values.chat.back"
								data-touch-target
								routerLink={Routes.CoreValues()}
								routerDirection="back"
								color="light"
								className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							>
								<IonIcon icon={arrowLeftIcon} />
							</IonButton>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.core-values.not-found.title")}
						description={t("page.core-values.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary">
						<IonButton
							data-attr="page.core-values.chat.back"
							data-touch-target
							routerLink={Routes.CoreValues()}
							routerDirection="back"
							color="light"
							className="min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>
						{coreValue?.coreValue.emoji} {coreValue?.customName || coreValue?.coreValue.name}
					</IonTitle>
				</Toolbar>
			</IonHeader>
			<ChatProvider chatStore={chatStore}>
				<Content className="part-scroll:gap-8">
					<ChatContent>
						{!!coreValue && <CoreValueCard coreValue={coreValue} />}
						{[...(coreValue?.messages || [])].map(
							(message, i) =>
								message && (
									<div key={i} className="flex flex-col gap-4">
										{message.messageRole === "ASSISTANT" && message.content && (
											<ChatMessage sender="bot">{message.content}</ChatMessage>
										)}
										{message.messageRole === "USER" && (
											<ChatMessage sender="user">{message.content}</ChatMessage>
										)}
										{message.messageRole === "PROPOSAL" && message.refineProposal && (
											<>
												<ChatMessage className="px-2" sender="bot">
													<p className="px-1 font-medium text-brown-700">{message.content}</p>
													<CoreValueCard
														coreValue={{
															customName: message.refineProposal.name,
															description: message.refineProposal.description,
															reason: message.refineProposal.description,
															coreValue: coreValue!.coreValue,
														}}
													/>
												</ChatMessage>
												{!message.refineProposal.answered && (
													<ChatMessage className="w-full px-2" sender="bot">
														<p className="px-1 font-medium text-brown-700">
															{t("page.refine-core-value.confirm")}
														</p>
														<div className="grid grid-cols-2 gap-2">
															<Button
																data-attr="page.core-values.chat.refine-proposal.decline"
																fill="clear"
																color={"secondary"}
																className="rounded-lg border-2 border-brown-200 text-sm font-bold"
																onClick={async () => {
																	if (!coreValue) {
																		return;
																	}
																	await backendService.corevalues.coreValuesControllerDeclineRefineProposal(
																		coreValue.id,
																	);
																	chat.setStreamAssistantMessage(null);
																	chat.setStreamUserMessage(t("page.refine-core-value.no"), true);
																	mutationStream.mutate({
																		question: t("page.refine-core-value.no"),
																		chosenCoreValueId: id,
																	});
																}}
															>
																{t("page.refine-core-value.no")}
															</Button>
															<Button
																data-attr="page.core-values.chat.refine-proposal.accept"
																fill="clear"
																className="rounded-lg bg-orange-500 text-sm font-bold text-white"
																onClick={async () => {
																	if (!coreValue) {
																		return;
																	}
																	await backendService.corevalues.coreValuesControllerAcceptRefineProposal(
																		coreValue.id,
																	);
																	chat.setStreamAssistantMessage(null);
																	chat.setStreamUserMessage(t("page.refine-core-value.yes"), true);
																	mutationStream.mutate({
																		question: t("page.refine-core-value.yes"),
																		chosenCoreValueId: id,
																	});

																	presentModal("coreValuesPersonalizeCompleted", {
																		id: coreValue.id,
																	});
																}}
															>
																{t("page.refine-core-value.yes")}
															</Button>
														</div>
													</ChatMessage>
												)}
											</>
										)}
									</div>
								),
						)}
					</ChatContent>
				</Content>
				<IonFooter>
					<ChatFooter>
						<ChatInput
							onSubmit={async (content) => {
								mutationStream.mutate({ question: content, chosenCoreValueId: id });
								return true;
							}}
							canSendMessage={!unansweredProposal}
						/>
					</ChatFooter>
				</IonFooter>
			</ChatProvider>
		</IonPage>
	);
}
