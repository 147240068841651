import { IonButton } from "@ionic/react";
import { forwardRef } from "react";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { cx } from "@/lib/style/cva.config";

export const Button = forwardRef<HTMLIonButtonElement, React.ComponentProps<typeof IonButton>>(
	function Button(props, ref) {
		const PreferredHaptics = usePreferredHaptics();

		return (
			<IonButton
				ref={ref}
				{...props}
				data-touch-target
				className={cx("m-0 cursor-pointer", props.className)}
				onClick={(event) => {
					PreferredHaptics.impact();
					props.onClick?.(event);
				}}
			/>
		);
	},
);
