export const createFetch = <TResponse extends Response>({
	skip,
	refresh,
	transform,
	authenticate,
	unauthenticate,
	logger = console,
}: {
	skip?: (context: { request: RequestInit | undefined; response: Response }) => boolean;
	refresh: () => Promise<TResponse>;
	transform: (response: TResponse) => {
		tokenType?: "Bearer" | (string & {});
		accessToken?: string;
	};
	authenticate: (response: TResponse) => void;
	unauthenticate: VoidFunction;
	logger?: Pick<typeof console, "error">;
}): typeof fetch => {
	let activePromise: Promise<TResponse> | undefined;

	return async (input, init) => {
		const response = await fetch(input, init);

		const context = { request: init, response };

		if (response.ok || !init?.headers || response.status !== 401 || skip?.(context)) {
			return response;
		}

		try {
			const refreshResponse = await (activePromise ??= refresh());
			activePromise = undefined;

			if (!refreshResponse.ok) {
				throw new Error("Refresh Invalid", { cause: refreshResponse });
			}

			authenticate(refreshResponse);

			const token = transform(refreshResponse);

			if (token.accessToken) {
				// @ts-expect-error - You should not mutate the provided headers, but here we put new values on.
				init.headers.Authorization = `${token.tokenType ?? "Bearer"} ${token.accessToken}`;
			}

			return await fetch(input, init);
		} catch (error) {
			logger.error("Refresh Error", error);
			unauthenticate?.();
			return response;
		} finally {
			activePromise = undefined;
		}
	};
};
